export const envirnoment = {
	playStoreAppURL:
		"https://play.google.com/store/apps/details?id=com.mycleanerhelpapp",
	appStoreURL: "#",
	websiteURL: "https://www.mycleanerhelp.co.uk",
	websiteURLWithoutHTTPs: "www.mycleanerhelp.co.uk",
	lambdaAPI: "https://ssc1x99b0c.execute-api.eu-west-2.amazonaws.com/prod",
	graphqlAPI:
		"https://7uhokesrfve45ims5efpm3do4m.appsync-api.eu-west-2.amazonaws.com/graphql",
	graphqlAPIKey: "da2-eop5en2xjbcmbc2kk36ztg6lk4",
};
