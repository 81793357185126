/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:3b88ea89-4772-4688-a11b-ae8c509c8efa",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_EKZoqQk3f",
    "aws_user_pools_web_client_id": "5t7t19rpq3gnhj9uh4nggaeu3l",
    "oauth": {
        "domain": "mycleanerhelpsocialauth-dev.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.mycleanerhelp.co.uk/",
        "redirectSignOut": "https://www.mycleanerhelp.co.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://7uhokesrfve45ims5efpm3do4m.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-eop5en2xjbcmbc2kk36ztg6lk4",
    "aws_user_files_s3_bucket": "mycleanerhelpcontentbucket165259-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "d35e0cd6664c427d87015086c633cc81",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
