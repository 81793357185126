import { ActionType } from "Interfaces/ActionType";

export interface LayoutState {
	didLoad: boolean;
}

const initialState: LayoutState = {
	didLoad: false,
};
export default function (state = initialState, action: ActionType) {
	const { type, payload } = action;
	switch (type) {
		case "DID_LOAD":
			return {
				...state,
				didLoad: true,
			};
		default:
			return state;
	}
}
