import React, { useEffect, useState } from "react";
import classNames from "classnames";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import {
	Box,
	Container,
	Paper,
	Grid,
	Button,
	Snackbar,
	CircularProgress,
} from "@mui/material";
import Alert from "@mui/material/Alert";

// core components
import HeaderLinks from "components/Header/HeaderLinks";
import Parallax from "components/Parallax/Parallax";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import useStyles from "assets/styles";
import { Auth } from "aws-amplify";

import { InputField, RadioGroupField } from "../../components/FormFields";
import { $FIXME } from "Helpers/constants";
import { Link } from "react-router-dom";
import { goTo } from "../../routeHistory";

export const rolesCollection = [
	{
		value: "1",
		label: "Service Provider",
	},
	{
		value: "0",
		label: "Customer",
	},
];

export default function VerifyAccountPage(props: $FIXME) {
	const classes = useStyles();

	const { ...rest } = props;
	const [errorVerifyAccount, setErrorVerifyAccount] = useState(false);

	function _handleSubmitForVerification(values: any, actions: any) {
		let metaData: any = {
			clientMetadata: {
				groupName: values.role ? values.role : "0",
			},
		};
		Auth.confirmSignUp(values.username, values.authCode, metaData)
			.then(() => goTo("/signin"))
			.catch((err) => {
				actions.setSubmitting(false);
				setErrorVerifyAccount(true);
			});
	}

	useEffect(() => {
		(window as Window).scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Header
				color="transparent"
				brand="My Cleaner Help"
				rightLinks={<HeaderLinks />}
				fixed
				changeColorOnScroll={{
					height: 200,
					color: "white",
				}}
				{...rest}
			/>
			<Parallax small image="/img/home/cleaning_services.webp">
				<div className={classes.container}>
					<Grid container style={{ zIndex: 19 }}>
						<Grid item xs={12} sm={12} md={12}>
							<h1 className={classes.titleWhite}>Account Verification</h1>
						</Grid>
					</Grid>
				</div>
			</Parallax>
			<div
				className={classNames(
					classes.main,
					classes.mainRaised,
					classes.section
				)}
			>
				<Container>
					<div className={classNames(classes.justifyCenter, classes.section)}>
						<Grid
							container
							spacing={2}
							className={classNames(classes.justifyCenter, classes.section)}
							style={{ margin: "100" }}
						>
							<Grid item xs={12} sm={12} md={6}>
								<Paper elevation={4} className={classes.p25}>
									<h2 className={classes.blueText + " " + classes.title}>
										Verify Account
									</h2>
									<Container>
										<Formik
											initialValues={{
												username: "",
												authCode: "",
												role: "1",
											}}
											validationSchema={Yup.object().shape({
												username: Yup.string()
													.email("Must be a valid email")
													.max(50)
													.required("Email is required"),
												authCode: Yup.string()
													.max(15)
													.required("Verification Code is required"),
												role: Yup.string().required("This field is required"),
											})}
											onSubmit={_handleSubmitForVerification}
										>
											{({ isSubmitting }) => (
												<Form id="accountVerificationForm">
													<Grid container spacing={1}>
														<Grid item xs={12} sm={12}>
															<p className={classes.description}>
																Registring As Customer or Service Provider?
															</p>
															<RadioGroupField
																name="role"
																label="Role"
																data={rolesCollection}
																fullWidth
															/>
														</Grid>
														<Grid item xs={12} sm={12}>
															<span className={classes.description}>
																Email:
															</span>
															<InputField
																type="text"
																name="username"
																label="Email"
																fullWidth
															/>
														</Grid>
														<Grid item xs={12} sm={12}>
															<span className={classes.description}>
																Verification Code:
															</span>
															<InputField
																type="text"
																name="authCode"
																label="Verification Code"
																fullWidth
															/>
														</Grid>
														<Grid item xs={12} sm={12}>
															<Box className={classes.py10}>
																<Button
																	disabled={isSubmitting}
																	type="submit"
																	variant="contained"
																	color="primary"
																>
																	Verify{" "}
																	{isSubmitting && (
																		<CircularProgress size={24} />
																	)}
																</Button>
															</Box>
														</Grid>
														<Grid item xs={12} sm={12}>
															<span className={classes.description}>
																Don't have an account?{" "}
																<Link to="/signup">Sign Up</Link> now, it takes
																just a few seconds. Already registered?{" "}
																<Link to="/signin"> Sign in here.</Link>
															</span>
														</Grid>
													</Grid>
												</Form>
											)}
										</Formik>
									</Container>
								</Paper>
							</Grid>
						</Grid>
					</div>
				</Container>
			</div>

			<Snackbar
				open={errorVerifyAccount}
				autoHideDuration={6000}
				onClose={() => setErrorVerifyAccount(false)}
			>
				<Alert onClose={() => setErrorVerifyAccount(false)} severity="error">
					Unable to Verify you Account!
				</Alert>
			</Snackbar>
			<Footer />
		</div>
	);
}
