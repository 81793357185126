import React from "react";
import at from "lodash.at";
import { useField } from "formik";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

interface SelectFieldProp {
	data: any[];
	label: string;
	name: string;
	fullWidth?: boolean;
	disabled?: boolean;
	[x: string]: any;
}

function RadioGroupField(props: SelectFieldProp) {
	const [field, meta] = useField(props);
	const [touched, error] = at(meta, "touched", "error");
	const isError = touched && error && true;
	function _renderHelperText() {
		if (isError) {
			return <FormHelperText>{error}</FormHelperText>;
		}
	}

	return (
		<FormControl
			{...props.x}
			variant="outlined"
			fullWidth
			disabled={props.disabled}
			error={isError}
			row="true"
		>
			<RadioGroup {...field} row>
				{props.data.map((item: any, index: any) => (
					<FormControlLabel
						key={index}
						value={item.value}
						disabled={props.disabled}
						control={<Radio size="small" />}
						label={item.label}
					/>
				))}
			</RadioGroup>

			{_renderHelperText()}
		</FormControl>
	);
}

export default RadioGroupField;
