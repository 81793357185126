export const theme = {
	White: "#FFFFFF",
	BackgroundLight: "#eef1f2",
	Border: "#707070",
	Blue: "#4886B0",
	LightBlue: "#0092C7",
	DarkBlue: "#15457F",
	lightGreySecondary: "#C7C7C7",
	GreySecondary: "#717171",
	Grey: "#727272",
	LightGrey: "#DFE3EB",
	DarkGrey: "#555555",
	Black: "#000000",
	Green: "#00A210",
	LightGreen: "#36A000",
	LightRed: "#D74532",
	Danger: "#FF2717",
};
