import React from "react";
import classNames from "classnames";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import useStyles from "assets/styles";
import { Link } from "react-router-dom";
import { envirnoment } from "envirnoment";

interface FooterProps {
	whiteFont?: boolean;
}

export default function Footer(props: FooterProps) {
	const classes = useStyles();
	const { whiteFont } = props;
	return (
		<footer className={classNames(classes.footer, classes.footerWhiteFont)}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6} md={3}>
					<Link to="/" className={classNames([classes.block])}>
						<img
							src="/img/mycleanerhelp-logo.webp"
							alt="My Cleaner Help Logo"
							width="150px"
							height="150px"
						/>
					</Link>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<List className={classes.list}>
						<ListItem className={classes.block}>
							<h3 className={classes.titleWhite}>Legal</h3>
						</ListItem>
						<ListItem className={classes.block}>
							<Link
								to="/privacy-policy"
								className={classNames([classes.block])}
							>
								Privacy Policy
							</Link>
						</ListItem>
						<ListItem className={classes.block}>
							<Link
								to="/terms-and-conditions"
								className={classNames([classes.block])}
							>
								Terms and conditions
							</Link>
						</ListItem>
						<ListItem className={classes.block}>
							<Link
								to="/website-disclaimer"
								className={classNames([classes.block])}
							>
								Website Disclaimer
							</Link>
						</ListItem>
						<ListItem className={classes.block}>
							<Link
								to="/cookies-policy"
								className={classNames([classes.block])}
							>
								Cookies Policy
							</Link>
						</ListItem>
					</List>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<List className={classes.list}>
						<ListItem className={classes.block}>
							<h3 className={classes.titleWhite}>Help and Advice</h3>
						</ListItem>
						<ListItem className={classes.block}>
							<Link to="/blog" className={classNames([classes.block])}>
								Blog
							</Link>
						</ListItem>
						<ListItem className={classes.block}>
							<Link to="/contact" className={classNames([classes.block])}>
								Contact Us
							</Link>
						</ListItem>
						<ListItem className={classes.block}>
							<Link to="/faq" className={classNames([classes.block])}>
								FAQ
							</Link>
						</ListItem>
						<ListItem className={classes.block}>
							<Link to="/quote" className={classNames([classes.block])}>
								Get Quote
							</Link>
						</ListItem>
						<ListItem className={classes.block}>
							<a
								href={envirnoment.websiteURL + "/sitemap.xml"}
								target="_blank"
								className={classes.dropdownLink}
								style={{ display: "inline" }}
							>
								Sitemap
							</a>
						</ListItem>
					</List>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<ListItem className={classes.block}>
						<h3 className={classes.titleWhite}>Get in Touch</h3>
					</ListItem>
					<ListItem className={classes.block}>
						<Link to="/careers" className={classNames([classes.block])}>
							Careers
						</Link>
					</ListItem>
					<ListItem className={classes.block}>
						<Link
							to="/affiliate-program"
							className={classNames([classes.block])}
						>
							Partnership
						</Link>
					</ListItem>
					<ListItem className={classes.block}>
						<a
							href="https://twitter.com/mycleanerhelp"
							target="_blank"
							className={classes.dropdownLink}
							style={{ display: "inline" }}
						>
							<i className={classes.socialIcons + " fab fa-twitter"} />
						</a>
						<a
							href="https://fb.me/mycleanerhelp"
							target="_blank"
							className={classes.dropdownLink}
							style={{ display: "inline" }}
						>
							{" "}
							<i className={classes.socialIcons + " fab fa-facebook"} />
						</a>
						<a
							href="https://www.instagram.com/mycleanerhelp/"
							target="_blank"
							className={classes.dropdownLink}
							style={{ display: "inline" }}
						>
							<i className={classes.socialIcons + " fab fa-instagram"} />
						</a>
					</ListItem>
				</Grid>
			</Grid>
		</footer>
	);
}
