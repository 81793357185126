export type $FIXME = any;
export const NAME = "IMMIX";

//Users
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
//Application Settings
export const APPLICATION_SETTINGS = "APPLICATION_SETTINGS";

export const heardFromCollection = [
	{
		value: "Friend",
		label: "Friend",
	},
	{
		value: "SocialMedia",
		label: "Social Media",
	},
	{
		value: "Other",
		label: "Other",
	},
];

enum STATUS {
	pending,
	approved = 1,
	disabled = 2,
}

enum TRANSPORTTYPE {
	unknown,
	public,
	private = 2,
}

enum SERVICETYPE {
	unknown,
	regularcleaning,
	deepcleaning,
	endoftenancycleaning,
}

enum PAYMENTFRQUENCY {
	unknown,
	weekly,
	biweekly,
	monthly,
}

export const buttonLinks = [
	{
		value: "book-now",
		label: "Book Now",
	},
	{
		value: "contact",
		label: "Contact Us",
	},
];

export const GiftCardPrice = [
	{
		value: 50,
		label: "50",
	},
	{
		value: 100,
		label: "100",
	},
	{
		value: 150,
		label: "150",
	},
	{
		value: 200,
		label: "200",
	},
	{
		value: 250,
		label: "250",
	},
	{
		value: 300,
		label: "300",
	},
	{
		value: 350,
		label: "350",
	},
	{
		value: 400,
		label: "400",
	},
];

export const TakeHomeRate = [
	{
		value: 9.5,
		label: "9.5",
	},
	{
		value: 10,
		label: "10",
	},
	{
		value: 10.5,
		label: "10.5",
	},
];

export const rattingList = [
	{
		value: 0,
		label: "0",
	},
	{
		value: 1,
		label: "1",
	},
	{
		value: 2,
		label: "2",
	},
	{
		value: 3,
		label: "3",
	},
	{
		value: 3.5,
		label: "3.5",
	},
	{
		value: 4,
		label: "4",
	},
	{
		value: 4.5,
		label: "4.5",
	},
	{
		value: 5,
		label: "5",
	},
];

export const radiusList = [
	{
		value: 3,
		label: "3",
	},
	{
		value: 4,
		label: "4",
	},
	{
		value: 5,
		label: "5",
	},
	{
		value: 6,
		label: "6",
	},
	{
		value: 7,
		label: "7",
	},
	{
		value: 8,
		label: "8",
	},
	{
		value: 9,
		label: "9",
	},
	{
		value: 10,
		label: "10",
	},
];

export const countList = [
	{
		value: "1",
		label: "1",
	},
	{
		value: "2",
		label: "2",
	},
	{
		value: "3",
		label: "3",
	},
	{
		value: "4",
		label: "4",
	},
	{
		value: "5",
		label: "5",
	},
	{
		value: "6",
		label: "6",
	},
	{
		value: "7",
		label: "7",
	},
	{
		value: "8",
		label: "8",
	},
];

export const COUNTRY_CODE = "+44";
export const serviceTypes = [
	{
		value: "regularcleaning",
		label: "Regular Cleaning",
	},
	{
		value: "deepcleaning",
		label: "Deep Cleaning",
	},
	{
		value: "endoftenancycleaning",
		label: "End of Tenancy Cleaning",
	},
];

export const properTypes = [
	{
		value: "house",
		label: "House",
	},
	{
		value: "flat",
		label: "Flat or Apartment",
	},
	{
		value: "commercial",
		label: "Commercial Property",
	},
	{
		value: "other",
		label: "Other",
	},
];

export const bedroomTypes = [
	{
		value: "studio",
		label: "Studio",
	},
	{
		value: "0bedroom",
		label: "0 Bedroom",
	},
	{
		value: "1bedroom",
		label: "1 Bedroom",
	},
	{
		value: "2bedrooms",

		label: "2 Bedrooms",
	},
	{
		value: "3bedrooms",
		label: "3 Bedrooms",
	},
	{
		value: "4bedrooms",
		label: "4 Bedrooms",
	},
	{
		value: "5bedrooms",
		label: "5 Bedrooms",
	},
];

export const bathroomTypes = [
	{
		value: "1bathroom",
		label: "1 Bathroom",
	},
	{
		value: "2bathrooms",
		label: "2 Bathrooms",
	},
	{
		value: "1bathroom+additionaltoilet",
		label: "1 Bathroom + additional Toilet",
	},
	{
		value: "2bathrooms+additionaltoilet",
		label: "2 Bathrooms + additional Toilet",
	},
];

// How often Do you need cleaning?

export const frequencyTypes = [
	{
		value: "daily",
		label: "Daily",
	},
	{
		value: "twiceaweek",
		label: "Twice a week",
	},
	{
		value: "weekly",
		label: "Weekly",
	},
	{
		value: "everyotherweek",
		label: "Every other week",
	},
	{
		value: "onetimeclean",
		label: "One time Clean",
	},
];

// What are the best Days for cleaning?

export const daysTypes = [
	{
		value: "any",
		label: "Any",
	},
	{
		value: "monday",
		label: "Monday",
	},
	{
		value: "tuesday",
		label: "Tuesday",
	},
	{
		value: "wednesday",
		label: "Wednesday",
	},
	{
		value: "thursday",
		label: "Thursday",
	},
	{
		value: "friday",
		label: "Friday",
	},

	{
		value: "saturday",
		label: "Saturday",
	},
	{
		value: "sunday",
		label: "Sunday",
	},
];

// Preferred start time

export const startTimeTypes = [
	{
		value: "anytimeflexible",
		label: "Flexible-Anytime",
	},
	{
		value: "morningbefore12pm",
		label: "Morning - Before 12 pm",
	},
	{
		value: "afternoonafter12pm",
		label: "Afternoon after 12 pm",
	},
];

// How Many Hours Do You Need?

export const hoursTypes = [
	{
		value: "2.5",
		label: "2.5",
	},
	{
		value: "3.5",
		label: "3.5",
	},
	{
		value: "4",
		label: "4",
	},
	{
		value: "4.5",
		label: "4.5",
	},
	{
		value: "5",
		label: "5",
	},
	{
		value: "5.5",
		label: "5.5",
	},
	{
		value: "6",
		label: "6",
	},
	{
		value: "6.5",
		label: "6.5",
	},
	{
		value: "7",
		label: "7",
	},
	{
		value: "7.5",
		label: "7.5",
	},
	{
		value: "8",
		label: "8",
	},
];
export const experienceYearsCollection = [
	{
		value: "One year",
		label: "One year",
	},
	{
		value: "Two years",
		label: "Two years",
	},
	{
		value: "Three years",
		label: "Three years",
	},
	{
		value: "Four years",
		label: "Four years",
	},
	{
		value: "Five years",
		label: "Five years",
	},
	{
		value: "Five years plus",
		label: "Five years plus",
	},
];

export const transportTypes = [
	{
		value: "public",
		label: "Public",
	},
	{
		value: "private",
		label: "Private",
	},
];

export const dbsCBRcheckCollection = [
	{
		value: "Yes",
		label: "Yes",
	},
	{
		value: "No",
		label: "No",
	},
];

export const paymentFrequencyCollection = [
	{
		value: "weekly",
		label: "Weekly",
	},
	{
		value: "biweekly",
		label: "Biweekly",
	},
	{
		value: "monthly",
		label: "Monthly",
	},
];

export const startTimeCollection = [
	{
		value: "8:00",
		label: "8:00",
	},
	{
		value: "8:30",
		label: "8:30",
	},
	{
		value: "9:00",
		label: "9:00",
	},
	{
		value: "9:30",
		label: "9:30",
	},
	{
		value: "10:00",
		label: "10:00",
	},
	{
		value: "10:30",
		label: "10:30",
	},
	{
		value: "11:00",
		label: "11:00",
	},
	{
		value: "11:30",
		label: "11:30",
	},
	{
		value: "12:00",
		label: "12:00",
	},
	{
		value: "12:30",
		label: "12:30",
	},
	{
		value: "13:00",
		label: "13:00",
	},
	{
		value: "13:30",
		label: "13:30",
	},
	{
		value: "14:00",
		label: "14:00",
	},
	{
		value: "14:30",
		label: "14:30",
	},
	{
		value: "15:00",
		label: "15:00",
	},
	{
		value: "15:30",
		label: "15:30",
	},
	{
		value: "16:00",
		label: "16:00",
	},
	{
		value: "17:30",
		label: "17:30",
	},
	{
		value: "18:00",
		label: "18:00",
	},
	{
		value: "18:30",
		label: "18:30",
	},
	{
		value: "19:00",
		label: "19:00",
	},
	{
		value: "19:30",
		label: "19:30",
	},
	{
		value: "20:00",
		label: "20:00",
	},
];

export const endTimeCollection = [
	{
		value: "8:00",
		label: "8:00",
	},
	{
		value: "8:30",
		label: "8:30",
	},
	{
		value: "9:00",
		label: "9:00",
	},
	{
		value: "9:30",
		label: "9:30",
	},
	{
		value: "10:00",
		label: "10:00",
	},
	{
		value: "10:30",
		label: "10:30",
	},
	{
		value: "11:00",
		label: "11:00",
	},
	{
		value: "11:30",
		label: "11:30",
	},
	{
		value: "12:00",
		label: "12:00",
	},
	{
		value: "12:30",
		label: "12:30",
	},
	{
		value: "13:00",
		label: "13:00",
	},
	{
		value: "13:30",
		label: "13:30",
	},
	{
		value: "14:00",
		label: "14:00",
	},
	{
		value: "14:30",
		label: "14:30",
	},
	{
		value: "15:00",
		label: "15:00",
	},
	{
		value: "15:30",
		label: "15:30",
	},
	{
		value: "16:00",
		label: "16:00",
	},
	{
		value: "17:30",
		label: "17:30",
	},
	{
		value: "18:00",
		label: "18:00",
	},
	{
		value: "18:30",
		label: "18:30",
	},
	{
		value: "19:00",
		label: "19:00",
	},
	{
		value: "19:30",
		label: "19:30",
	},
	{
		value: "20:00",
		label: "20:00",
	},
];

export const currencyList = [
	{
		value: "USD",
		label: "USD",
	},
	{
		value: "EUR",
		label: "EUR",
	},
	{
		value: "GBP",
		label: "GBP",
	},
];

export const currencyLableList = [
	{
		value: "$",
		label: "$",
	},
	{
		value: "€",
		label: "€",
	},
	{
		value: "£",
		label: "£",
	},
];

export const serviceLocations = [
	{
		value: "House",
		label: "House",
	},
	{
		value: "Office/Commercial",
		label: "Office/Commercial",
	},
];

export const workingHoursCollection = [
	{
		value: "2.5",
		label: "2.5 Hours",
	},
	{
		value: "3.5",
		label: "3.5 Hours",
	},
	{
		value: "4",
		label: "4.0 Hours",
	},
	{
		value: "4.5",
		label: "4.5 Hours",
	},
	{
		value: "5",
		label: "5.0 Hours",
	},
	{
		value: "5.5",
		label: "5.5 Hours",
	},
	{
		value: "6",
		label: "6.0 Hours",
	},
	{
		value: "6.5",
		label: "6.5 Hours",
	},
	{
		value: "7",
		label: "7.0 Hours",
	},
	{
		value: "7.5",
		label: "7.5 Hours",
	},
	{
		value: "8",
		label: "8.0 Hours",
	},
];

export const serviceFrequencyCollection = [
	{
		value: "Daily",
		label: "Every Day",
	},
	{
		value: "EveryWeek",
		label: "Every Week",
	},
	{
		value: "MoreThanTwoWeeks",
		label: "More then 2 weeks",
	},
	{
		value: "OneOff",
		label: "One-Off",
	},
];

export const rattingOp = [
	{
		value: "eq",
		label: "Equal",
	},
	{
		value: "le",
		label: "Less Than Equal",
	},
	{
		value: "ge",
		label: "Greater Than Equal",
	},
];

export const cities = [
	{
		value: "Birmingham",
		label: "Birmingham",
	},
	{
		value: "Leeds",
		label: "Leeds",
	},
	{
		value: "GreaterManchester",
		label: "Greater Manchester",
	},
	{
		value: "London",
		label: "London",
	},
	{
		value: "Brighton",
		label: "Brighton",
	},
];

export const countries = [
	{
		value: "GB",
		label: "UK",
	},
];

export const BOOKINGTYPESLIST = [
	{
		value: "unknown",
		label: "",
	},
	{
		value: "custom",
		label: "custom",
	},
	{
		value: "offer",
		label: "offer",
	},
];

export const BOOKINGSTATUSLIST = [
	{
		value: "pending",
		label: "Pending",
	},
	{
		value: "confirmed",
		label: "Confirmed",
	},
	{
		value: "completed",
		label: "Completed",
	},
	{
		value: "canceled",
		label: "Canceled",
	},
];

export const CANCLLEDBYLIST = [
	{
		value: "unknown",
		label: "",
	},
	{
		value: "customer",
		label: "Customer",
	},
	{
		value: "company",
		label: "Company",
	},
	{
		value: "serviceprovider",
		label: "Service Provider",
	},
];

export const SPProfileStatusList = [
	{
		value: "",
		label: "--None--",
	},
	{
		value: "pending",
		label: "Pending",
	},
	{
		value: "approved",
		label: "Approved",
	},
	{
		value: "disabled",
		label: "Disabled",
	},
];

export const paidFilter = [
	{
		value: "all",
		label: "All",
	},
	{
		value: "true",
		label: "Yes",
	},
	{
		value: "false",
		label: "No",
	},
];
