import React, { useEffect } from "react";
import at from "lodash.at";
import { useField } from "formik";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

interface SelectFieldProp {
	data: any[];
	label: string;
	name: string;
	fullWidth?: boolean;
	[x: string]: any;
}

function MultiSelectField(props: SelectFieldProp) {
	const [field, meta, helper] = useField(props);
	const { value: selectedValue } = field;
	const [touched, error] = at(meta, "touched", "error");
	const isError = touched && error && true;
	const { setValue } = helper;
	const [personName, setPersonName] = React.useState<string[]>([]);

	function _renderHelperText() {
		if (isError) {
			return <FormHelperText>{error}</FormHelperText>;
		}
	}

	const handleChange = (event: SelectChangeEvent<string[]>) => {
		setPersonName(event.target.value as string[]);
		setValue(event.target.value as string[]);
	};

	useEffect(() => {
		if (selectedValue) {
			setPersonName(selectedValue);
		}
	}, [selectedValue]);

	return (
		<FormControl
			{...props.x}
			style={{ zIndex: 1 }}
			variant="outlined"
			fullWidth
			error={isError}
		>
			<InputLabel
				style={{
					zIndex: 2,
					backgroundColor: "#fff",
					paddingRight: "2px",
					paddingLeft: "2px",
				}}
			>
				{props.label}
			</InputLabel>
			<Select {...field} multiple value={personName} onChange={handleChange}>
				{props.data.map((item: any, index: any) => (
					<MenuItem key={index} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
			{_renderHelperText()}
		</FormControl>
	);
}

export default MultiSelectField;
