import { ActionType } from "Interfaces/ActionType";
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, $FIXME } from "Helpers/constants";

export interface AuthState {
	redirectToReferrer: boolean;
	authenticated: boolean;
	authUser: $FIXME;
	redirectLogin: boolean;
	loginLoading: boolean;
	group: string;
	username: string;
}

const initialState: AuthState = {
	redirectToReferrer: false,
	authenticated: false,
	authUser: {},
	redirectLogin: false,
	loginLoading: false,
	username: "",
	group: "",
};
export default function (state = initialState, action: ActionType) {
	const { type, payload } = action;
	switch (type) {
		case LOGIN_SUCCESS:
			const groups: any =
				payload.signInUserSession.accessToken.payload["cognito:groups"];
			let g = "Customer";
			if (groups !== undefined && groups.length === 1)
				g = payload.signInUserSession.accessToken.payload["cognito:groups"][0]
					? payload.signInUserSession.accessToken.payload["cognito:groups"][0]
					: "Customer";
			return {
				...state,
				redirectLogin: true,
				authenticated: true,
				authUser: payload.attributes,
				username: payload.username,
				group: g,
			};

		case LOGIN_FAIL:
			return {
				...state,
				loginLoading: false,
			};
		case LOGOUT:
			return {
				redirectToReferrer: false,
				authenticated: false,
				authUser: {},
				redirectLogin: false,
				loginLoading: false,
				username: "",
				group: "",
			};
		default:
			return state;
	}
}
