import { ActionType } from "Interfaces/ActionType";
import { APPLICATION_SETTINGS } from "Helpers/constants";

export interface ApplicationSettingState {
	taxRate?: number;
	hourlyRate?: number;
	everyWeekHR?: number;
	moreThan2WeekHR?: number;
	oneOfHR?: number;
	currencyLable?: string;
	currencyCode?: string;
	cleaningSuppliesRate?: number;
	platformFee?: number;
	mondayW?: boolean;
	tuesdayW?: boolean;
	wednesdayW?: boolean;
	thursdayW?: boolean;
	fridayW?: boolean;
	saturdayW?: boolean;
	sundayW?: boolean;
	createdAt?: string;
	updatedAt?: string;
	postalCodeRegx?: string;
}

const initialState: ApplicationSettingState = {
	taxRate: 0,
	hourlyRate: 0,
	everyWeekHR: 0,
	moreThan2WeekHR: 0,
	oneOfHR: 0,
	currencyLable: "",
	currencyCode: "",
	platformFee: 0,
	cleaningSuppliesRate: 0,
	mondayW: false,
	tuesdayW: false,
	wednesdayW: false,
	thursdayW: false,
	fridayW: false,
	saturdayW: false,
	sundayW: false,
	postalCodeRegx: "",
};

export default function (state = initialState, action: ActionType) {
	const { type, payload } = action;
	switch (type) {
		case APPLICATION_SETTINGS:
			return {
				...state,
				taxRate: payload.taxRate,
				hourlyRate: payload.hourlyRate,
				everyWeekHR: payload.everyWeekHR,
				moreThan2WeekHR: payload.moreThan2WeekHR,
				oneOfHR: payload.oneOfHR,
				currencyLable: payload.currencyLable,
				currencyCode: payload.currencyCode,
				platformFee: payload.platformFee,
				cleaningSuppliesRate: payload.cleaningSuppliesRate,
				mondayW: payload.mondayW,
				tuesdayW: payload.tuesdayW,
				wednesdayW: payload.wednesdayW,
				thursdayW: payload.thursdayW,
				fridayW: payload.fridayW,
				saturdayW: payload.saturdayW,
				sundayW: payload.sundayW,
				postalCodeRegx: payload.postalCodeRegx,
			};
		default:
			return state;
	}
}
