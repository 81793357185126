import {
	AnyAction,
	combineReducers,
	Dispatch,
	Middleware,
	Reducer,
} from "redux";
import { configureStore } from "@reduxjs/toolkit";
import authReducer, { AuthState } from "./authReducers";

import layoutReducer, { LayoutState } from "./layoutReducer";
import applicationSettingReducer, {
	ApplicationSettingState,
} from "./applicationSettingReducers";
import { RouterState } from "redux-first-history";
export interface StateType {
	auth: AuthState;
	layout: LayoutState;
	appSetting: ApplicationSettingState;
}

export const buildReducers = (
	routerReducer: Reducer<RouterState, AnyAction>,
	middleware: Middleware<Record<any, any>, any, Dispatch<AnyAction>>[]
) =>
	configureStore({
		reducer: combineReducers({
			auth: authReducer,
			layout: layoutReducer,
			appSetting: applicationSettingReducer,
			router: routerReducer,
		}),
		middleware,
	});
