import React, { useState, useEffect } from "react";
import { useField } from "formik";
import Grid from "@mui/material/Grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { StateType } from "Reducers";
import { TextFieldProps } from "@mui/material";
import TextField from "@mui/material/TextField";

export default function DatePickerField(props: any) {
	const [field, meta, helper] = useField(props);
	const { touched, error } = meta;
	const { setValue } = helper;
	const isError = touched && error && true;
	const { value } = field;
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const applicationSettings = useSelector(
		(state: StateType) => state?.appSetting
	);

	useEffect(() => {
		if (value) {
			const date = new Date(value);
			setSelectedDate(date);
		}
	}, [value]);

	function disableWeekends(date: any) {
		if (!applicationSettings.mondayW && date.getDay() === 1) return true;
		else if (!applicationSettings.tuesdayW && date.getDay() === 2) return true;
		else if (!applicationSettings.wednesdayW && date.getDay() === 3)
			return true;
		else if (!applicationSettings.thursdayW && date.getDay() === 4) return true;
		else if (!applicationSettings.fridayW && date.getDay() === 5) return true;
		else if (!applicationSettings.saturdayW && date.getDay() === 6) return true;
		else if (!applicationSettings.sundayW && date.getDay() === 0) return true;
		else return false;
	}

	function _onChange(date: any) {
		if (date) {
			setSelectedDate(date);
			try {
				const ISODateString = date.toISOString();
				setValue(ISODateString);
			} catch (error) {
				setValue(date);
			}
		} else {
			setValue(date);
		}
	}

	return (
		<Grid container>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					{...field}
					{...props}
					value={selectedDate}
					onChange={_onChange}
					error={isError}
					invalidDateMessage={isError && error}
					helperText={isError && error}
					shouldDisableDate={disableWeekends}
					renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
						<TextField {...params} fullWidth sx={{ margin: "20px 0" }} />
					)}
				/>
			</LocalizationProvider>
		</Grid>
	);
}
