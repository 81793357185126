/* eslint-disable */
import React, { useEffect, useState } from "react";
//import { Route, Switch, useLocation } from 'react-router-dom';
import routes from "Helpers/routes";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { theme } from "Helpers/theme";
import { getApplicationSetting } from "graphql/queries";
import { graphqlOperation } from "aws-amplify";
import { PUBLICAPI } from "Helpers/Axios";
import { APPLICATION_SETTINGS, LOGIN_SUCCESS } from "Helpers/constants";
import { useDispatch } from "react-redux";

import { Auth } from "aws-amplify";
import CircularProgress from "@mui/material/CircularProgress";
import ReactGA from "react-ga";

import { Routes, Route, useLocation } from "react-router-dom";

import useGTM from "Hooks/useGTM";

import PrivateRoutes from "Utils/PrivateRoute";

const TRACKING_ID = "UA-201403145-1";
ReactGA.initialize(TRACKING_ID);

const color = createTheme({
	palette: {
		primary: {
			main: "#4886B0",
		},
		secondary: {
			light: "#0066ff",
			main: "#0044ff",
			contrastText: "#ffcc00",
		},
		error: {
			main: "#D74532",
		},
		success: {
			main: "#36A000",
			dark: "#008329",
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
});

export default () => {
	let location = useLocation();
	const themes = useTheme();
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const initialLoad = async () => {
		try {
			await Auth?.currentUserInfo()?.then(async (e) => {
				if (e) {
					await Auth?.currentSession()?.then(async (response) => {
						await dispatch({
							type: LOGIN_SUCCESS,
							payload: {
								...e,
								signInUserSession: response,
							},
						});
					});
				}
			});
		} catch (ex: any) {}
		setLoading(false);
		PUBLICAPI.post(
			"",
			graphqlOperation(getApplicationSetting, {
				id: "defaultsettings",
			})
		).then((res: any) => {
			if (res.data) {
				const post: any = res?.data?.data?.getApplicationSetting;
				if (post) {
					localStorage.setItem("postalCodeRegx", post.postalCodeRegx);
					dispatch({ type: APPLICATION_SETTINGS, payload: post });
				}
			}
		});
	};

	const { initializeGTM } = useGTM()

	useEffect(() => {
		initialLoad();
		initializeGTM();
	}, []);

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
		(window as any).gtag("config", "G-5Q095XLDXR", {
			page_title: location.pathname,
			page_path: location.pathname,
		});
	}, [location]);

	const publicRoutes = routes.filter((route) => !route.private);
	const privateRoutes = routes.filter((route) => route.private);
	return (
		<ThemeProvider theme={{ ...themes, ...theme, ...color }}>
			{loading ? (
				<div style={{ margin: "30px auto", textAlign: "center" }}>
					<CircularProgress />
				</div>
			) : (
				<Routes>
					{publicRoutes.map((publicRoute:any, idx) => (
						<Route
							key={`${idx}-public`}
							path={publicRoute.path}
							element={<publicRoute.element />}
						/>
					))}
					<Route element={<PrivateRoutes />}>
						{privateRoutes.map((privateRoute:any, idx) => (
							<Route
								key={`${idx}-private`}
								path={privateRoute.path}
								element={<privateRoute.element />}
							/>
						))}
					</Route>
					{/*{routes.map((route: RouteType, i: number) => {*/}
					{/*	return route.private === true ? (*/}
					{/*		<PrivateRoute key={i} {...route} component={route.component} />*/}
					{/*	) : (*/}
					{/*		<Route key={i} {...route} />*/}
					{/*	);*/}
					{/*})}*/}
				</Routes>
			)}
		</ThemeProvider>
	);
	// return (
	// 	<Router>
	// 		<Route exact path="/AboutPage" component={AboutPage} />
	// 		<Route exact path="/ServicesPage" component={ServicesPage} />
	// 		<Route exact path="/WorkWithUsPage" component={WorkWithUsPage} />
	// 		<Route exact path="/GiftCardPage" component={GiftCardPage} />
	// 		<Route
	// 			exact
	// 			path="/EndOfTenancyCleaningPage"
	// 			component={EndOfTenancyCleaningPage}
	// 		/>
	// 		<Route exact path="/AuthPages" component={SigninPage} />
	// 		<Route exact path="/BlogPage" component={BlogPage} />
	// 		<Route exact path="/CareerPage" component={CareerPage} />
	// 		<Route exact path="/FAQPage" component={FAQPage} />
	// 		<Route exact path="/QuotePage" component={QuotePage} />
	// 		<Route exact path="/LandingPage" component={LandingPage} />
	// 		<Route
	// 			exact
	// 			path="/AffiliateProgramPage"
	// 			component={AffiliateProgramPage}
	// 		/>
	// 		<Route exact path="/ContactPage" component={ContactPage} />
	// 		<Route exact path="/LondonLandingPage" component={LondonLandingPage} />
	// 		<Route exact path="/AuthPages" component={SignUpPage} />
	// 	</Router>
	// );
};
