/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApplication = /* GraphQL */ `
	query GetApplication($id: ID!) {
		getApplication(id: $id) {
			id
			careerPostID
			cvPath
			desiredPay
			message
			createdAt
			updatedAt
		}
	}
`;
export const listApplications = /* GraphQL */ `
	query ListApplications(
		$filter: ModelApplicationFilterInput
		$limit: Int
		$nextToken: String
	) {
		listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				careerPostID
				cvPath
				desiredPay
				message
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getContactMessage = /* GraphQL */ `
	query GetContactMessage($id: ID!) {
		getContactMessage(id: $id) {
			id
			name
			email
			subject
			message
			createdAt
			updatedAt
		}
	}
`;
export const listContactMessages = /* GraphQL */ `
	query ListContactMessages(
		$filter: ModelContactMessageFilterInput
		$limit: Int
		$nextToken: String
	) {
		listContactMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				email
				subject
				message
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getQuote = /* GraphQL */ `
	query GetQuote($id: ID!) {
		getQuote(id: $id) {
			id
			name
			email
			phone
			desiredDate
			message
			postalCode
			serviceType
			createdAt
			updatedAt
		}
	}
`;
export const listQuotes = /* GraphQL */ `
	query ListQuotes(
		$filter: ModelQuoteFilterInput
		$limit: Int
		$nextToken: String
	) {
		listQuotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				email
				phone
				desiredDate
				message
				postalCode
				serviceType
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getCustomer = /* GraphQL */ `
	query GetCustomer($id: ID!) {
		getCustomer(id: $id) {
			id
			firstName
			lastName
			email
			phone
			address
			city
			postalCode
			country
			createdAt
			updatedAt
		}
	}
`;
export const listCustomers = /* GraphQL */ `
	query ListCustomers(
		$filter: ModelCustomerFilterInput
		$limit: Int
		$nextToken: String
	) {
		listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				firstName
				lastName
				email
				phone
				address
				city
				postalCode
				country
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getGiftCard = /* GraphQL */ `
	query GetGiftCard($id: ID!) {
		getGiftCard(id: $id) {
			id
			name
			email
			receiverName
			receiverEmail
			amount
			message
			paymentToken
			usedFlag
			createdAt
			updatedAt
		}
	}
`;
export const listGiftCards = /* GraphQL */ `
	query ListGiftCards(
		$filter: ModelGiftCardFilterInput
		$limit: Int
		$nextToken: String
	) {
		listGiftCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				email
				receiverName
				receiverEmail
				amount
				message
				paymentToken
				usedFlag
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getPromoCode = /* GraphQL */ `
	query GetPromoCode($id: ID!) {
		getPromoCode(id: $id) {
			id
			code
			activeFlag
			onlyNewUserFlag
			discountPercent
			description
			createdAt
			updatedAt
		}
	}
`;
export const listPromoCodes = /* GraphQL */ `
	query ListPromoCodes(
		$filter: ModelPromoCodeFilterInput
		$limit: Int
		$nextToken: String
	) {
		listPromoCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				code
				activeFlag
				onlyNewUserFlag
				discountPercent
				description
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getServiceProvider = /* GraphQL */ `
	query GetServiceProvider($id: ID!) {
		getServiceProvider(id: $id) {
			id
			firstName
			lastName
			email
			phone
			address
			city
			postalCode
			country
			heardFrom
			experienceYears
			transportType
			serviceType
			profileStatus
			expectedJoinDate
			verificationDocumentPath
			verificationDocumentPath1
			latitude
			longitude
			radius
			mondayW
			tuesdayW
			wednesdayW
			thursdayW
			fridayW
			saturdayW
			sundayW
			mondayST
			tuesdayST
			wednesdayST
			thursdayST
			fridayST
			saturdayST
			sundayST
			mondayET
			tuesdayET
			wednesdayET
			thursdayET
			fridayET
			saturdayET
			sundayET
			paymentFrequency
			dbsCBRcheck
			selfEmployeedCondition
			termsCondition
			ownerId
			accountNumber
			sortCode
			bookings {
				items {
					id
					bookingID
					serviceProviderID
					serviceProviderComments
					tipValue
					earning
					totalHours
					plaftformEarning
					spRate
					spEmail
					spFirstName
					spLastName
					serviceStatus
					bookingStatus
					paid
					createdAt
					updatedAt
				}
				nextToken
			}
			rejectedBooking {
				date
				bookingId
			}
			serviceRate
			ratting
			bookingCount
			createdAt
			updatedAt
		}
	}
`;
export const listServiceProviders = /* GraphQL */ `
	query ListServiceProviders(
		$filter: ModelServiceProviderFilterInput
		$limit: Int
		$nextToken: String
	) {
		listServiceProviders(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				firstName
				lastName
				email
				phone
				address
				city
				postalCode
				country
				heardFrom
				experienceYears
				transportType
				serviceType
				profileStatus
				expectedJoinDate
				verificationDocumentPath
				verificationDocumentPath1
				latitude
				longitude
				radius
				mondayW
				tuesdayW
				wednesdayW
				thursdayW
				fridayW
				saturdayW
				sundayW
				mondayST
				tuesdayST
				wednesdayST
				thursdayST
				fridayST
				saturdayST
				sundayST
				mondayET
				tuesdayET
				wednesdayET
				thursdayET
				fridayET
				saturdayET
				sundayET
				paymentFrequency
				dbsCBRcheck
				selfEmployeedCondition
				termsCondition
				ownerId
				accountNumber
				sortCode
				bookings {
					nextToken
				}
				rejectedBooking {
					date
					bookingId
				}
				serviceRate
				ratting
				bookingCount
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getBooking = /* GraphQL */ `
	query GetBooking($id: ID!) {
		getBooking(id: $id) {
			id
			customerId
			bookingStatus
			ratting
			rattingComment
			totalPrice
			actualTotalPrice
			cancelledDate
			cancelledBy
			cancellationReason
			cancellationFee
			tipValue
			latitude
			longitude
			bookingType
			offerId
			serviceLocation
			serviceType
			bedroomCount
			bathroomCount
			ironing
			laundry
			innerFridge
			interiorWindows
			innerOven
			hours
			cleaningSupplies
			serviceFrequency
			startDate
			startTime
			firstName
			lastName
			email
			phone
			address
			city
			commentsForAssistant
			commentsForUs
			postalCode
			country
			offerNotifications
			termsCondition
			paymentToken
			serviceProviderEmailIds
			bookingServiceProviders {
				items {
					id
					bookingID
					serviceProviderID
					serviceProviderComments
					tipValue
					earning
					totalHours
					plaftformEarning
					spRate
					spEmail
					spFirstName
					spLastName
					serviceStatus
					bookingStatus
					paid
					createdAt
					updatedAt
				}
				nextToken
			}
			offer {
				id
				title
				description
				serviceLocation
				cleanerCount
				price
				salePrice
				expectedHours
				roomsCount
				bathroomCount
				buttonText
				buttonLink
				serviceType
				createdAt
				updatedAt
			}
			cleanerCount
			promo
			promoCode {
				id
				code
				activeFlag
				onlyNewUserFlag
				discountPercent
				description
				createdAt
				updatedAt
			}
			giftCard {
				id
				name
				email
				receiverName
				receiverEmail
				amount
				message
				paymentToken
				usedFlag
				createdAt
				updatedAt
			}
			createdAt
			updatedAt
		}
	}
`;
export const listBookings = /* GraphQL */ `
	query ListBookings(
		$filter: ModelBookingFilterInput
		$limit: Int
		$nextToken: String
	) {
		listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				customerId
				bookingStatus
				ratting
				rattingComment
				totalPrice
				actualTotalPrice
				cancelledDate
				cancelledBy
				cancellationReason
				cancellationFee
				tipValue
				latitude
				longitude
				bookingType
				offerId
				serviceLocation
				serviceType
				bedroomCount
				bathroomCount
				ironing
				laundry
				innerFridge
				interiorWindows
				innerOven
				hours
				cleaningSupplies
				serviceFrequency
				startDate
				startTime
				firstName
				lastName
				email
				phone
				address
				city
				commentsForAssistant
				commentsForUs
				postalCode
				country
				offerNotifications
				termsCondition
				paymentToken
				serviceProviderEmailIds
				bookingServiceProviders {
					nextToken
				}
				offer {
					id
					title
					description
					serviceLocation
					cleanerCount
					price
					salePrice
					expectedHours
					roomsCount
					bathroomCount
					buttonText
					buttonLink
					serviceType
					createdAt
					updatedAt
				}
				cleanerCount
				promo
				promoCode {
					id
					code
					activeFlag
					onlyNewUserFlag
					discountPercent
					description
					createdAt
					updatedAt
				}
				giftCard {
					id
					name
					email
					receiverName
					receiverEmail
					amount
					message
					paymentToken
					usedFlag
					createdAt
					updatedAt
				}
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getBSP = /* GraphQL */ `
	query GetBSP($id: ID!) {
		getBSP(id: $id) {
			id
			bookingID
			serviceProviderID
			serviceProvider {
				id
				firstName
				lastName
				email
				phone
				address
				city
				postalCode
				country
				heardFrom
				experienceYears
				transportType
				serviceType
				profileStatus
				expectedJoinDate
				verificationDocumentPath
				verificationDocumentPath1
				latitude
				longitude
				radius
				mondayW
				tuesdayW
				wednesdayW
				thursdayW
				fridayW
				saturdayW
				sundayW
				mondayST
				tuesdayST
				wednesdayST
				thursdayST
				fridayST
				saturdayST
				sundayST
				mondayET
				tuesdayET
				wednesdayET
				thursdayET
				fridayET
				saturdayET
				sundayET
				paymentFrequency
				dbsCBRcheck
				selfEmployeedCondition
				termsCondition
				ownerId
				accountNumber
				sortCode
				bookings {
					nextToken
				}
				rejectedBooking {
					date
					bookingId
				}
				serviceRate
				ratting
				bookingCount
				createdAt
				updatedAt
			}
			booking {
				id
				customerId
				bookingStatus
				ratting
				rattingComment
				totalPrice
				actualTotalPrice
				cancelledDate
				cancelledBy
				cancellationReason
				cancellationFee
				tipValue
				latitude
				longitude
				bookingType
				offerId
				serviceLocation
				serviceType
				bedroomCount
				bathroomCount
				ironing
				laundry
				innerFridge
				interiorWindows
				innerOven
				hours
				cleaningSupplies
				serviceFrequency
				startDate
				startTime
				firstName
				lastName
				email
				phone
				address
				city
				commentsForAssistant
				commentsForUs
				postalCode
				country
				offerNotifications
				termsCondition
				paymentToken
				serviceProviderEmailIds
				bookingServiceProviders {
					nextToken
				}
				offer {
					id
					title
					description
					serviceLocation
					cleanerCount
					price
					salePrice
					expectedHours
					roomsCount
					bathroomCount
					buttonText
					buttonLink
					serviceType
					createdAt
					updatedAt
				}
				cleanerCount
				promo
				promoCode {
					id
					code
					activeFlag
					onlyNewUserFlag
					discountPercent
					description
					createdAt
					updatedAt
				}
				giftCard {
					id
					name
					email
					receiverName
					receiverEmail
					amount
					message
					paymentToken
					usedFlag
					createdAt
					updatedAt
				}
				createdAt
				updatedAt
			}
			serviceProviderComments
			tipValue
			earning
			totalHours
			plaftformEarning
			spRate
			spEmail
			spFirstName
			spLastName
			serviceStatus
			bookingStatus
			paid
			serviceProviderCheckins {
				items {
					id
					bspID
					startTime
					endTime
					checkinState
					paid
					createdAt
					updatedAt
				}
				nextToken
			}
			createdAt
			updatedAt
		}
	}
`;
export const listBSPS = /* GraphQL */ `
	query ListBSPS(
		$filter: ModelBSPFilterInput
		$limit: Int
		$nextToken: String
	) {
		listBSPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				bookingID
				serviceProviderID
				serviceProvider {
					id
					firstName
					lastName
					email
					phone
					address
					city
					postalCode
					country
					heardFrom
					experienceYears
					transportType
					serviceType
					profileStatus
					expectedJoinDate
					verificationDocumentPath
					verificationDocumentPath1
					latitude
					longitude
					radius
					mondayW
					tuesdayW
					wednesdayW
					thursdayW
					fridayW
					saturdayW
					sundayW
					mondayST
					tuesdayST
					wednesdayST
					thursdayST
					fridayST
					saturdayST
					sundayST
					mondayET
					tuesdayET
					wednesdayET
					thursdayET
					fridayET
					saturdayET
					sundayET
					paymentFrequency
					dbsCBRcheck
					selfEmployeedCondition
					termsCondition
					ownerId
					accountNumber
					sortCode
					serviceRate
					ratting
					bookingCount
					createdAt
					updatedAt
				}
				booking {
					id
					customerId
					bookingStatus
					ratting
					rattingComment
					totalPrice
					actualTotalPrice
					cancelledDate
					cancelledBy
					cancellationReason
					cancellationFee
					tipValue
					latitude
					longitude
					bookingType
					offerId
					serviceLocation
					serviceType
					bedroomCount
					bathroomCount
					ironing
					laundry
					innerFridge
					interiorWindows
					innerOven
					hours
					cleaningSupplies
					serviceFrequency
					startDate
					startTime
					firstName
					lastName
					email
					phone
					address
					city
					commentsForAssistant
					commentsForUs
					postalCode
					country
					offerNotifications
					termsCondition
					paymentToken
					serviceProviderEmailIds
					cleanerCount
					promo
					createdAt
					updatedAt
				}
				serviceProviderComments
				tipValue
				earning
				totalHours
				plaftformEarning
				spRate
				spEmail
				spFirstName
				spLastName
				serviceStatus
				bookingStatus
				paid
				serviceProviderCheckins {
					nextToken
				}
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getBookingServiceProviderCheckin = /* GraphQL */ `
	query GetBookingServiceProviderCheckin($id: ID!) {
		getBookingServiceProviderCheckin(id: $id) {
			id
			bspID
			startTime
			endTime
			checkinState
			paid
			createdAt
			updatedAt
		}
	}
`;
export const listBookingServiceProviderCheckins = /* GraphQL */ `
	query ListBookingServiceProviderCheckins(
		$filter: ModelBookingServiceProviderCheckinFilterInput
		$limit: Int
		$nextToken: String
	) {
		listBookingServiceProviderCheckins(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				bspID
				startTime
				endTime
				checkinState
				paid
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getServiceProviderBookingNotification = /* GraphQL */ `
	query GetServiceProviderBookingNotification($id: ID!) {
		getServiceProviderBookingNotification(id: $id) {
			id
			bookingID
			serviceProviderID
			serviceProvider {
				id
				firstName
				lastName
				email
				phone
				address
				city
				postalCode
				country
				heardFrom
				experienceYears
				transportType
				serviceType
				profileStatus
				expectedJoinDate
				verificationDocumentPath
				verificationDocumentPath1
				latitude
				longitude
				radius
				mondayW
				tuesdayW
				wednesdayW
				thursdayW
				fridayW
				saturdayW
				sundayW
				mondayST
				tuesdayST
				wednesdayST
				thursdayST
				fridayST
				saturdayST
				sundayST
				mondayET
				tuesdayET
				wednesdayET
				thursdayET
				fridayET
				saturdayET
				sundayET
				paymentFrequency
				dbsCBRcheck
				selfEmployeedCondition
				termsCondition
				ownerId
				accountNumber
				sortCode
				bookings {
					nextToken
				}
				rejectedBooking {
					date
					bookingId
				}
				serviceRate
				ratting
				bookingCount
				createdAt
				updatedAt
			}
			booking {
				id
				customerId
				bookingStatus
				ratting
				rattingComment
				totalPrice
				actualTotalPrice
				cancelledDate
				cancelledBy
				cancellationReason
				cancellationFee
				tipValue
				latitude
				longitude
				bookingType
				offerId
				serviceLocation
				serviceType
				bedroomCount
				bathroomCount
				ironing
				laundry
				innerFridge
				interiorWindows
				innerOven
				hours
				cleaningSupplies
				serviceFrequency
				startDate
				startTime
				firstName
				lastName
				email
				phone
				address
				city
				commentsForAssistant
				commentsForUs
				postalCode
				country
				offerNotifications
				termsCondition
				paymentToken
				serviceProviderEmailIds
				bookingServiceProviders {
					nextToken
				}
				offer {
					id
					title
					description
					serviceLocation
					cleanerCount
					price
					salePrice
					expectedHours
					roomsCount
					bathroomCount
					buttonText
					buttonLink
					serviceType
					createdAt
					updatedAt
				}
				cleanerCount
				promo
				promoCode {
					id
					code
					activeFlag
					onlyNewUserFlag
					discountPercent
					description
					createdAt
					updatedAt
				}
				giftCard {
					id
					name
					email
					receiverName
					receiverEmail
					amount
					message
					paymentToken
					usedFlag
					createdAt
					updatedAt
				}
				createdAt
				updatedAt
			}
			title
			message
			createdAt
			updatedAt
		}
	}
`;
export const listServiceProviderBookingNotifications = /* GraphQL */ `
	query ListServiceProviderBookingNotifications(
		$filter: ModelServiceProviderBookingNotificationFilterInput
		$limit: Int
		$nextToken: String
	) {
		listServiceProviderBookingNotifications(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				bookingID
				serviceProviderID
				serviceProvider {
					id
					firstName
					lastName
					email
					phone
					address
					city
					postalCode
					country
					heardFrom
					experienceYears
					transportType
					serviceType
					profileStatus
					expectedJoinDate
					verificationDocumentPath
					verificationDocumentPath1
					latitude
					longitude
					radius
					mondayW
					tuesdayW
					wednesdayW
					thursdayW
					fridayW
					saturdayW
					sundayW
					mondayST
					tuesdayST
					wednesdayST
					thursdayST
					fridayST
					saturdayST
					sundayST
					mondayET
					tuesdayET
					wednesdayET
					thursdayET
					fridayET
					saturdayET
					sundayET
					paymentFrequency
					dbsCBRcheck
					selfEmployeedCondition
					termsCondition
					ownerId
					accountNumber
					sortCode
					serviceRate
					ratting
					bookingCount
					createdAt
					updatedAt
				}
				booking {
					id
					customerId
					bookingStatus
					ratting
					rattingComment
					totalPrice
					actualTotalPrice
					cancelledDate
					cancelledBy
					cancellationReason
					cancellationFee
					tipValue
					latitude
					longitude
					bookingType
					offerId
					serviceLocation
					serviceType
					bedroomCount
					bathroomCount
					ironing
					laundry
					innerFridge
					interiorWindows
					innerOven
					hours
					cleaningSupplies
					serviceFrequency
					startDate
					startTime
					firstName
					lastName
					email
					phone
					address
					city
					commentsForAssistant
					commentsForUs
					postalCode
					country
					offerNotifications
					termsCondition
					paymentToken
					serviceProviderEmailIds
					cleanerCount
					promo
					createdAt
					updatedAt
				}
				title
				message
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const customerByEmail = /* GraphQL */ `
	query CustomerByEmail(
		$email: String
		$sortDirection: ModelSortDirection
		$filter: ModelCustomerFilterInput
		$limit: Int
		$nextToken: String
	) {
		customerByEmail(
			email: $email
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				firstName
				lastName
				email
				phone
				address
				city
				postalCode
				country
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const promoCodeByCode = /* GraphQL */ `
	query PromoCodeByCode(
		$code: String
		$sortDirection: ModelSortDirection
		$filter: ModelPromoCodeFilterInput
		$limit: Int
		$nextToken: String
	) {
		promoCodeByCode(
			code: $code
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				code
				activeFlag
				onlyNewUserFlag
				discountPercent
				description
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const spByLatLng = /* GraphQL */ `
	query SpByLatLng(
		$latitude: Float
		$longitude: ModelFloatKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelServiceProviderFilterInput
		$limit: Int
		$nextToken: String
	) {
		spByLatLng(
			latitude: $latitude
			longitude: $longitude
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				firstName
				lastName
				email
				phone
				address
				city
				postalCode
				country
				heardFrom
				experienceYears
				transportType
				serviceType
				profileStatus
				expectedJoinDate
				verificationDocumentPath
				verificationDocumentPath1
				latitude
				longitude
				radius
				mondayW
				tuesdayW
				wednesdayW
				thursdayW
				fridayW
				saturdayW
				sundayW
				mondayST
				tuesdayST
				wednesdayST
				thursdayST
				fridayST
				saturdayST
				sundayST
				mondayET
				tuesdayET
				wednesdayET
				thursdayET
				fridayET
				saturdayET
				sundayET
				paymentFrequency
				dbsCBRcheck
				selfEmployeedCondition
				termsCondition
				ownerId
				accountNumber
				sortCode
				bookings {
					nextToken
				}
				rejectedBooking {
					date
					bookingId
				}
				serviceRate
				ratting
				bookingCount
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const spByCity = /* GraphQL */ `
	query SpByCity(
		$city: String
		$sortDirection: ModelSortDirection
		$filter: ModelServiceProviderFilterInput
		$limit: Int
		$nextToken: String
	) {
		spByCity(
			city: $city
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				firstName
				lastName
				email
				phone
				address
				city
				postalCode
				country
				heardFrom
				experienceYears
				transportType
				serviceType
				profileStatus
				expectedJoinDate
				verificationDocumentPath
				verificationDocumentPath1
				latitude
				longitude
				radius
				mondayW
				tuesdayW
				wednesdayW
				thursdayW
				fridayW
				saturdayW
				sundayW
				mondayST
				tuesdayST
				wednesdayST
				thursdayST
				fridayST
				saturdayST
				sundayST
				mondayET
				tuesdayET
				wednesdayET
				thursdayET
				fridayET
				saturdayET
				sundayET
				paymentFrequency
				dbsCBRcheck
				selfEmployeedCondition
				termsCondition
				ownerId
				accountNumber
				sortCode
				bookings {
					nextToken
				}
				rejectedBooking {
					date
					bookingId
				}
				serviceRate
				ratting
				bookingCount
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const bookingByEmail = /* GraphQL */ `
	query BookingByEmail(
		$email: String
		$sortDirection: ModelSortDirection
		$filter: ModelBookingFilterInput
		$limit: Int
		$nextToken: String
	) {
		bookingByEmail(
			email: $email
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				customerId
				bookingStatus
				ratting
				rattingComment
				totalPrice
				actualTotalPrice
				cancelledDate
				cancelledBy
				cancellationReason
				cancellationFee
				tipValue
				latitude
				longitude
				bookingType
				offerId
				serviceLocation
				serviceType
				bedroomCount
				bathroomCount
				ironing
				laundry
				innerFridge
				interiorWindows
				innerOven
				hours
				cleaningSupplies
				serviceFrequency
				startDate
				startTime
				firstName
				lastName
				email
				phone
				address
				city
				commentsForAssistant
				commentsForUs
				postalCode
				country
				offerNotifications
				termsCondition
				paymentToken
				serviceProviderEmailIds
				bookingServiceProviders {
					nextToken
				}
				offer {
					id
					title
					description
					serviceLocation
					cleanerCount
					price
					salePrice
					expectedHours
					roomsCount
					bathroomCount
					buttonText
					buttonLink
					serviceType
					createdAt
					updatedAt
				}
				cleanerCount
				promo
				promoCode {
					id
					code
					activeFlag
					onlyNewUserFlag
					discountPercent
					description
					createdAt
					updatedAt
				}
				giftCard {
					id
					name
					email
					receiverName
					receiverEmail
					amount
					message
					paymentToken
					usedFlag
					createdAt
					updatedAt
				}
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const bookingByStatus = /* GraphQL */ `
	query BookingByStatus(
		$bookingStatus: BOOKINGSTATUS
		$sortDirection: ModelSortDirection
		$filter: ModelBookingFilterInput
		$limit: Int
		$nextToken: String
	) {
		bookingByStatus(
			bookingStatus: $bookingStatus
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				customerId
				bookingStatus
				ratting
				rattingComment
				totalPrice
				actualTotalPrice
				cancelledDate
				cancelledBy
				cancellationReason
				cancellationFee
				tipValue
				latitude
				longitude
				bookingType
				offerId
				serviceLocation
				serviceType
				bedroomCount
				bathroomCount
				ironing
				laundry
				innerFridge
				interiorWindows
				innerOven
				hours
				cleaningSupplies
				serviceFrequency
				startDate
				startTime
				firstName
				lastName
				email
				phone
				address
				city
				commentsForAssistant
				commentsForUs
				postalCode
				country
				offerNotifications
				termsCondition
				paymentToken
				serviceProviderEmailIds
				bookingServiceProviders {
					nextToken
				}
				offer {
					id
					title
					description
					serviceLocation
					cleanerCount
					price
					salePrice
					expectedHours
					roomsCount
					bathroomCount
					buttonText
					buttonLink
					serviceType
					createdAt
					updatedAt
				}
				cleanerCount
				promo
				promoCode {
					id
					code
					activeFlag
					onlyNewUserFlag
					discountPercent
					description
					createdAt
					updatedAt
				}
				giftCard {
					id
					name
					email
					receiverName
					receiverEmail
					amount
					message
					paymentToken
					usedFlag
					createdAt
					updatedAt
				}
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getPostalCode = /* GraphQL */ `
	query GetPostalCode($id: ID!) {
		getPostalCode(id: $id) {
			id
			code
			city
			country
			createdAt
			updatedAt
		}
	}
`;
export const listPostalCodes = /* GraphQL */ `
	query ListPostalCodes(
		$filter: ModelPostalCodeFilterInput
		$limit: Int
		$nextToken: String
	) {
		listPostalCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				code
				city
				country
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const postalCodeByCode = /* GraphQL */ `
	query PostalCodeByCode(
		$code: String
		$sortDirection: ModelSortDirection
		$filter: ModelPostalCodeFilterInput
		$limit: Int
		$nextToken: String
	) {
		postalCodeByCode(
			code: $code
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				code
				city
				country
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getApplicationSetting = /* GraphQL */ `
	query GetApplicationSetting($id: ID!) {
		getApplicationSetting(id: $id) {
			id
			taxRate
			hourlyRate
			everyWeekHR
			moreThan2WeekHR
			oneOfHR
			currencyLable
			currencyCode
			cleaningSuppliesRate
			platformFee
			mondayW
			tuesdayW
			wednesdayW
			thursdayW
			fridayW
			saturdayW
			sundayW
			postalCodeRegx
			createdAt
			updatedAt
		}
	}
`;
export const listApplicationSettings = /* GraphQL */ `
	query ListApplicationSettings(
		$filter: ModelApplicationSettingFilterInput
		$limit: Int
		$nextToken: String
	) {
		listApplicationSettings(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				taxRate
				hourlyRate
				everyWeekHR
				moreThan2WeekHR
				oneOfHR
				currencyLable
				currencyCode
				cleaningSuppliesRate
				platformFee
				mondayW
				tuesdayW
				wednesdayW
				thursdayW
				fridayW
				saturdayW
				sundayW
				postalCodeRegx
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getBlogpost = /* GraphQL */ `
	query GetBlogpost($id: ID!) {
		getBlogpost(id: $id) {
			id
			title
			description
			createdAt
			updatedAt
		}
	}
`;
export const listBlogposts = /* GraphQL */ `
	query ListBlogposts(
		$filter: ModelBlogpostFilterInput
		$limit: Int
		$nextToken: String
	) {
		listBlogposts(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				title
				description
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getCareerpost = /* GraphQL */ `
	query GetCareerpost($id: ID!) {
		getCareerpost(id: $id) {
			id
			title
			description
			location
			applications {
				items {
					id
					careerPostID
					cvPath
					desiredPay
					message
					createdAt
					updatedAt
				}
				nextToken
			}
			createdAt
			updatedAt
		}
	}
`;
export const listCareerposts = /* GraphQL */ `
	query ListCareerposts(
		$filter: ModelCareerpostFilterInput
		$limit: Int
		$nextToken: String
	) {
		listCareerposts(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				title
				description
				location
				applications {
					nextToken
				}
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const getOffers = /* GraphQL */ `
	query GetOffers($id: ID!) {
		getOffers(id: $id) {
			id
			title
			description
			serviceLocation
			cleanerCount
			price
			salePrice
			expectedHours
			roomsCount
			bathroomCount
			buttonText
			buttonLink
			serviceType
			createdAt
			updatedAt
		}
	}
`;
export const listOffers = /* GraphQL */ `
	query ListOffers(
		$filter: ModelOffersFilterInput
		$limit: Int
		$nextToken: String
	) {
		listOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				title
				description
				serviceLocation
				cleanerCount
				price
				salePrice
				expectedHours
				roomsCount
				bathroomCount
				buttonText
				buttonLink
				serviceType
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
