import TagManager from "react-gtm-module";

type DataLayerData = {
	event: string;
	eventDetails: {
		category: string;
		action: string;
		label: string;
		currency?: string;
		value?: string;
	};
};

const useGTM = () => {
	const initializeGTM = () => {
		TagManager.initialize({
			gtmId: "GTM-KDB6HJ7",
		});
	};

	const tagPageVisit = (path: string) => {
		TagManager.dataLayer({
			dataLayer: {
				event: "PageView",
				path: path,
				eventDetails: null,
			},
		});
	};

	const tagFormSubmission = (data: DataLayerData | null) => {
		TagManager.dataLayer({
			dataLayer: {
				...data,
			},
		});
	};

	return { initializeGTM, tagPageVisit, tagFormSubmission };
};

export default useGTM;
