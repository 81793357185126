import loadable from "@loadable/component";

const LandingPage = loadable(() => import("views/LandingPage/LandingPage"));
const AboutPage = loadable(() => import("views/AboutPage/AboutPage"));
const QuotePage = loadable(() => import("views/QuotePage/QuotePage"));
const ServicesPage = loadable(() => import("views/ServicesPage/ServicesPage"));
const WorkWithUsPage = loadable(
	() => import("views/WorkWithUsPage/WorkWithUsPage")
);

const SPRegistrationWorkWithUsPage = loadable(
	() => import("views/WorkWithUsPage/SPRegistrationWorkWithUsPage")
);

const PartTimeFullTimePage = loadable(
	() => import("views/WorkWithUsPage/FullTimePartTimePage")
);


const AgencyCleaningJobsPage = loadable(
	() => import("views/WorkWithUsPage/AgencyCleaningJobsPage")
);
const HousekeepingJobsBenefitsPage = loadable(
	() => import("views/WorkWithUsPage/HousekeepingJobsBenefitsPage")
);

const SPThanksPage = loadable(
	() => import("views/WorkWithUsPage/SPThanksPage")
);

const SPSignUpPage = loadable(
	() => import("views/AuthPages/SPRegistration/SPSignUpPage")
);

const SPVerifyAccountPage = loadable(
	() => import("views/AuthPages/SPRegistration/SPVerifyAccountPage")
);

const SPSignInPage = loadable(
	() => import("views/AuthPages/SPRegistration/SPSignInPage")
);


const ContactPage = loadable(() => import("views/ContactPage/ContactPage"));
const EndOfTenancyCleaningPage = loadable(
	() => import("views/EndOfTenancyCleaningPage/EndOfTenancyCleaningPage")
);
const BookNowPage = loadable(() => import("views/BookNowPage/BookNowPage"));
const AffiliateProgramPage = loadable(
	() => import("views/AffiliateProgramPage/AffiliateProgramPage")
);
const FAQPage = loadable(() => import("views/FAQPage/FAQPage"));
const MainDashboardPage = loadable(
	() => import("views/DashboardPages/MainDashboardPage")
);
const CookiesPage = loadable(() => import("views/LegalPages/CookiesPage"));
const GeneralDataProtectionPage = loadable(
	() => import("views/LegalPages/GeneralDataProtectionPage")
);
const TermsAndConditionsPage = loadable(
	() => import("views/LegalPages/TermsAndConditionsPage")
);
const PrivacyPolicyPage = loadable(
	() => import("views/LegalPages/PrivacyPolicyPage")
);
const DisclaimerPage = loadable(
	() => import("views/LegalPages/DisclaimerPage")
);
const NotFound = loadable(() => import("views/NotFound"));
const BlogDashPage = loadable(
	() => import("views/DashboardPages/BlogPages/BlogDashPage")
);
const BlogPostDashPage = loadable(
	() => import("views/DashboardPages/BlogPages/BlogPostDashPage")
);
const BlogPage = loadable(() => import("views/BlogPage/BlogPage"));
const BlogPostPage = loadable(() => import("views/BlogPage/BlogPostPage"));
const CareerPage = loadable(() => import("views/CareerPage/CareerPage"));
const CareerPostPage = loadable(
	() => import("views/CareerPage/CareerPostPage")
);
const CareerDashPage = loadable(
	() => import("views/DashboardPages/CareerPages/CareerDashPage")
);
const CareerPostDashPage = loadable(
	() => import("views/DashboardPages/CareerPages/CareerPostDashPage")
);
const UpdateCareerPostDashPage = loadable(
	() => import("views/DashboardPages/CareerPages/UpdateCareerPostDashPage")
);
const UpdateBlogPostDashPage = loadable(
	() => import("views/DashboardPages/BlogPages/UpdateBlogPostDashPage")
);
const GiftCardPage = loadable(() => import("views/GiftCardPage/GiftCardPage"));
const ServiceProviderDashPage = loadable(
	() =>
		import("views/DashboardPages/ServiceProviderPages/ServiceProviderDashPage")
);
const ViewServiceProviderPage = loadable(
	() =>
		import("views/DashboardPages/ServiceProviderPages/ViewServiceProviderPage")
);

import {
	NewPasswordPage,
	SignUpPage,
	SigninPage,
	ForgotPasswordPage,
	VerifyAccountPage,
} from "views/AuthPages";


const StaffingAgencyDetroitContactFormPage = loadable(
	() =>import("views/StaffingAgencyDetroitContactForm/StaffingAgencyDetroitContactFormPage")
);

const CommercialCleaningQuotesPage = loadable(
	() => import("views/CommercialCleaningQuotes/CommercialCleaningQuotesPage")
);

const CreateOfferDashPage = loadable(
	() => import("views/DashboardPages/OffersPages/CreateOfferDashPage")
);
const OffersDashPage = loadable(
	() => import("views/DashboardPages/OffersPages/OffersDashPage")
);
const UpdateOfferDashPage = loadable(
	() => import("views/DashboardPages/OffersPages/UpdateOfferDashPage")
);
const ContactMessageDashPage = loadable(
	() =>
		import("views/DashboardPages/ContactMessagePages/ContactMessageDashPage")
);
const ViewContactMessageDashPage = loadable(
	() =>
		import(
			"views/DashboardPages/ContactMessagePages/ViewContactMessageDashPage"
		)
);
const ApplicationSettingPage = loadable(
	() =>
		import("views/DashboardPages/ApplicationSettingPage/ApplicationSettingPage")
);
const SPViewProfilePage = loadable(
	() => import("views/DashboardPages/SP/ServiceProviderPages/SPViewProfilePage")
);
const CustomerProfilePage = loadable(
	() => import("views/DashboardPages/Customer/ProfilePage/CustomerProfilePage")
);
const CustomerDashPage = loadable(
	() => import("views/DashboardPages/CustomerPages/CustomerDashPage")
);
const ViewCustomerPage = loadable(
	() => import("views/DashboardPages/CustomerPages/ViewCustomerPage")
);
const BookingDashPage = loadable(
	() => import("views/DashboardPages/BookingPages/BookingDashPage")
);
const ViewBookingPage = loadable(
	() => import("views/DashboardPages/BookingPages/ViewBookingPage")
);
const CustomerBookingDashPage = loadable(
	() =>
		import("views/DashboardPages/Customer/BookingPages/CustomerBookingDashPage")
);
const CustomerViewBookingPage = loadable(
	() =>
		import("views/DashboardPages/Customer/BookingPages/CustomerViewBookingPage")
);
const MiscDashPage = loadable(
	() => import("views/DashboardPages/MiscPage/MiscDashPage")
);
const MiscDashPageSetting = loadable(
	() => import("views/DashboardPages/MiscPage/MiscDashPageSetting")
);
const CustomerBookingHistoryDashPage = loadable(
	() =>
		import(
			"views/DashboardPages/Customer/BookingPages/CustomerBookingHistoryDashPage"
		)
);
const PostalCodeDashPage = loadable(
	() => import("views/DashboardPages/PostalCodePages/PostalCodeDashPage")
);
const PostalCodePage = loadable(
	() => import("views/DashboardPages/PostalCodePages/PostalCodePage")
);
const ViewPostalCodePage = loadable(
	() => import("views/DashboardPages/PostalCodePages/ViewPostalCodePage")
);
const ServiceProviderBookingHistoryDashPage = loadable(
	() =>
		import(
			"views/DashboardPages/SP/BookingPages/ServiceProviderBookingHistoryDashPage"
		)
);
const ServiceProviderBookingDashPage = loadable(
	() =>
		import(
			"views/DashboardPages/SP/BookingPages/ServiceProviderBookingDashPage"
		)
);
const ServiceProviderViewBookingPage = loadable(
	() =>
		import(
			"views/DashboardPages/SP/BookingPages/ServiceProviderViewBookingPage"
		)
);
const AcceptBookingPage = loadable(
	() => import("views/DashboardPages/SP/BookingPages/AcceptBookingPage")
);
const QuoteDashPage = loadable(
	() => import("views/DashboardPages/QuotePages/QuoteDashPage")
);
const ViewQuoteDashPage = loadable(
	() => import("views/DashboardPages/QuotePages/ViewQuoteDashPage")
);
const ServiceProviderMapViewPage = loadable(
	() => import("views/DashboardPages/ReportPages/ServiceProviderMapView")
);
const BookingMapViewPage = loadable(
	() => import("views/DashboardPages/ReportPages/BookingMapView")
);
const PromoCodeDashPage = loadable(
	() => import("views/DashboardPages/PromoCodePages/PromoCodeDashPage")
);
const CreatePromoCodePage = loadable(
	() => import("views/DashboardPages/PromoCodePages/CreatePromoCodePage")
);
const UpdatePromoCodePage = loadable(
	() => import("views/DashboardPages/PromoCodePages/UpdatePromoCodePage")
);
const GiftCardDashPage = loadable(
	() => import("views/DashboardPages/GiftCard/GiftCardDashPage")
);
const MyGiftCardsDashPage = loadable(
	() =>
		import("views/DashboardPages/Customer/GiftCardPages/MyGiftCardsDashPage")
);
const OthersGiftCardsDashPage = loadable(
	() =>
		import(
			"views/DashboardPages/Customer/GiftCardPages/OthersGiftCardsDashPage"
		)
);
const PayrollDashPage = loadable(
	() => import("views/DashboardPages/PayrollPages/PayrollDashPage")
);
const NewInvoice = loadable(() => import("components/Invoice/NewInvoice"));
const CheckoutSuccess = loadable(
	() => import("views/BookNowPage/CheckoutSuccess")
);
const LondonLandingPage = loadable(
	() => import("views/LondonLandingPage/LondonLandingPage")
);
const FreeQuotePage = loadable(() => import("views/FreeQuotes/FreeQuotePage"));
const StaffStaffing = loadable(() => import("views/StaffStaffing/StaffStaffing"));

const CommercialCleaningDetroitPage = loadable(() => import("views/NewPages/CommercialCleaningDetroitPage"));
const StaffingAgencyDetroitPage = loadable(() => import("views/NewPages/StaffingAgencyDetroitPage"));
const DryWallServicePage = loadable(() => import("views/DryWallServicePage/DryWallServicePage"));


export default [
	{
		path: "/",
		element: LandingPage,
		exact: true,
	},
	{
		path: "/london",
		element: LondonLandingPage,
	},
	{
		path: "/about-us",
		element: AboutPage,
	},
	{
		path: "/quote",
		element: QuotePage,
	},
	{
		path: "/cleaning-quote",
		element: FreeQuotePage,
	},
	{
		path: "/staff-wanted",
		element: StaffStaffing,
	},
	{
		path: "/book-now",
		element: BookNowPage,
	},
	{
		path: "/checkout-successfull",
		element: CheckoutSuccess,
	},
	{
		path: "/cleaning-services",
		element: ServicesPage,
	},
	{
		path: "/work-with-us",
		element: WorkWithUsPage,
	},
	{
		path: "/full-time-part-time-cleaner",
		element: PartTimeFullTimePage,
	},
	{
		path: "/agency-cleaning-jobs",
		element: AgencyCleaningJobsPage,
	},
	{
		path: "/sp-thanks-page",
		element: SPThanksPage,
	},
	{
		path: "/service-provider-application-form",
		element: SPRegistrationWorkWithUsPage,
	},
	{
		path: "/housekeeping-jobs",
		element: HousekeepingJobsBenefitsPage,
	},

	{
		path: "/contact",
		element: ContactPage,
	},
	{
		path: "/giftcard",
		element: GiftCardPage,
	},
	{
		path: "/end-of-tenancy-cleaning",
		element: EndOfTenancyCleaningPage,
		private: false,
	},
	{
		path: "/affiliate-program",
		element: AffiliateProgramPage,
		private: false,
	},
	{
		path: "/faq",
		element: FAQPage,
	},
	{
		path: "/service-provider-signup",
		element: SPSignUpPage,
	},
	{
		path: "/service-provider-signin",
		element: SPSignInPage,
	},
	{
		path: "/service-provider-verifyaccount",
		element: SPVerifyAccountPage,
	},
	{
		path: "/locations/detroit/commercial-cleaning-detroit",
		element: CommercialCleaningDetroitPage,
	},
	{
		path: "/locations/detroit/commercial-cleaning-detroit-free-quote",
		element: CommercialCleaningQuotesPage,
	},
	{
		path: "/locations/staffing-agency-contact-form",
		element: StaffingAgencyDetroitContactFormPage,
	},
	{
		path: "/locations/staffing-agency",
		element: StaffingAgencyDetroitPage,
	},
	{
		path: "/locations/detroit/drywall-service",
		element: DryWallServicePage,
	},
	{
		path: "/signup",
		element: SignUpPage,
	},
	{
		path: "/signin",
		element: SigninPage,
	},
	{
		path: "/forgotpassword",
		element: ForgotPasswordPage,
	},
	{
		path: "/verifyaccount",
		element: VerifyAccountPage,
	},
	{
		path: "/newpassword",
		element: NewPasswordPage,
	},
	{
		path: "/cookies-policy",
		element: CookiesPage,
	},
	{
		path: "/general-data-protection",
		element: GeneralDataProtectionPage,
	},
	{
		path: "/terms-and-conditions",
		element: TermsAndConditionsPage,
	},
	{
		path: "/privacy-policy",
		element: PrivacyPolicyPage,
	},
	{
		path: "/website-disclaimer",
		element: DisclaimerPage,
	},
	{
		path: "/blogpost/:id",
		element: BlogPostPage,
	},
	{
		path: "/blog",
		element: BlogPage,
	},
	{
		path: "/careerspost/:id",
		element: CareerPostPage,
	},
	{
		path: "/careers",
		element: CareerPage,
	},
	{
		path: "/dashboard/blog",
		element: BlogDashPage,
		private: true,
	},
	{
		path: "/dashboard/blogpost",
		element: BlogPostDashPage,
		private: true,
	},
	{
		path: "/dashboard/updateblogpost/:id",
		element: UpdateBlogPostDashPage,
		private: true,
	},
	{
		path: "/dashboard/career",
		element: CareerDashPage,
		private: true,
	},
	{
		path: "/dashboard/careerpost",
		element: CareerPostDashPage,
		private: true,
	},
	{
		path: "/dashboard/updatecareerpost/:id",
		element: UpdateCareerPostDashPage,
		private: true,
	},
	{
		path: "/dashboard/serviceProviders",
		element: ServiceProviderDashPage,
		private: true,
	},
	{
		path: "/dashboard/serviceProvider/:id",
		element: ViewServiceProviderPage,
		private: true,
	},
	{
		path: "/dashboard/payroll",
		element: PayrollDashPage,
		private: true,
	},
	{
		path: "/dashboard/bookings",
		element: BookingDashPage,
		private: true,
	},
	{
		path: "/dashboard/booking/:id",
		element: ViewBookingPage,
		private: true,
	},
	{
		path: "/dashboard/customer/:id",
		element: ViewCustomerPage,
		private: true,
	},
	{
		path: "/dashboard/customers",
		element: CustomerDashPage,
		private: true,
	},
	{
		path: "/dashboard/offers",
		element: OffersDashPage,
		private: true,
	},
	{
		path: "/dashboard/newoffer",
		element: CreateOfferDashPage,
		private: true,
	},
	{
		path: "/dashboard/updateoffer/:id",
		element: UpdateOfferDashPage,
		private: true,
	},
	{
		path: "/dashboard/postalCodes",
		element: PostalCodeDashPage,
		private: true,
	},
	{
		path: "/dashboard/newPostalCode",
		element: PostalCodePage,
		private: true,
	},
	{
		path: "/dashboard/postalCode/:id",
		element: ViewPostalCodePage,
		private: true,
	},
	{
		path: "/dashboard/promoCodes",
		element: PromoCodeDashPage,
		private: true,
	},
	{
		path: "/dashboard/new-promo-code",
		element: CreatePromoCodePage,
		private: true,
	},
	{
		path: "/dashboard/update-promo-code/:id",
		element: UpdatePromoCodePage,
		private: true,
	},
	{
		path: "/dashboard/messages",
		element: ContactMessageDashPage,
		private: true,
	},
	{
		path: "/dashboard/viewmessage/:id",
		element: ViewContactMessageDashPage,
		private: true,
	},
	{
		path: "/dashboard/quotes",
		element: QuoteDashPage,
		private: true,
	},
	{
		path: "/dashboard/quote/:id",
		element: ViewQuoteDashPage,
		private: true,
	},
	{
		path: "/dashboard/applicationSetting/",
		element: ApplicationSettingPage,
		private: true,
	},
	{
		path: "/dashboard/miscellaneous/",
		element: MiscDashPage,
		private: true,
	},
	{
		path: "/dashboard/miscSetting/",
		element: MiscDashPageSetting,
		private: true,
	},
	{
		path: "/dashboard/giftcards",
		element: GiftCardDashPage,
		private: true,
	},
	{
		path: "/dashboard/reports/service-provider-map-view",
		element: ServiceProviderMapViewPage,
		private: true,
	},
	{
		path: "/dashboard/reports/booking-map-view",
		element: BookingMapViewPage,
		private: true,
	},
	{
		path: "/dashboard/sp/profile",
		element: SPViewProfilePage,
		private: true,
	},
	{
		path: "/dashboard/sp/acceptbooking/:id",
		element: AcceptBookingPage,
		private: true,
	},
	{
		path: "/dashboard/sp/booking/:id",
		element: ServiceProviderViewBookingPage,
		private: true,
	},
	{
		path: "/dashboard/sp/bookings",
		element: ServiceProviderBookingDashPage,
		private: true,
	},
	{
		path: "/dashboard/sp/booking-history",
		element: ServiceProviderBookingHistoryDashPage,
		private: true,
	},
	{
		path: "/dashboard/cs/booking/:id",
		element: CustomerViewBookingPage,
		private: true,
	},
	{
		path: "/dashboard/cs/bookings",
		element: CustomerBookingDashPage,
		private: true,
	},
	{
		path: "/dashboard/cs/booking-history",
		element: CustomerBookingHistoryDashPage,
		private: true,
	},
	{
		path: "/dashboard/cs/profile",
		element: CustomerProfilePage,
		private: true,
	},
	{
		path: "/dashboard/cs/my-gift-cards",
		element: MyGiftCardsDashPage,
		private: true,
	},
	{
		path: "/dashboard/cs/others-gift-cards",
		element: OthersGiftCardsDashPage,
		private: true,
	},
	{
		path: "/dashboard",
		element: MainDashboardPage,
		private: true,
	},
	{
		path: "/invoice/:id",
		element: NewInvoice,
		private: false,
	},
	{
		path: "*",
		element: NotFound,
	},
];
