import React, { useEffect, useState } from "react";
import classNames from "classnames";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import {
	Box,
	Container,
	Paper,
	Grid,
	Button,
	Snackbar,
	CircularProgress,
} from "@mui/material";
import Alert from "@mui/material/Alert";

// core components
import HeaderLinks from "components/Header/HeaderLinks";
import Parallax from "components/Parallax/Parallax";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import { $FIXME } from "Helpers/constants";
import useStyles from "assets/styles";
import { Auth } from "aws-amplify";
import { InputField } from "../../components/FormFields";
import { Link } from "react-router-dom";
import { goTo } from "../../routeHistory";

export default function SignUpPage(props: $FIXME) {
	const classes = useStyles();

	const { ...rest } = props;
	const phoneRegExp = /^(\+\d{10,15})$/;
	const [successSignUp, setSuccessSignUp] = useState(false);
	const [errorSignUp, setErrorSignUp] = useState(false);

	const isAuthenticated = async () => {
		await Auth.currentSession().then((response: any) => {
			if (response?.isValid()) {
				goTo("/dashboard");
			}
		});
	};

	function _sleep(ms: any) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	function _handleSubmitForSignUp(values: any, actions: any) {
		let a: any = {};
		a.username = values.email;
		a.password = values.password;
		a.attributes = {
			email: values.email,
			phone_number: values.phone_number,
		};
		Auth.signUp(a)
			.then(() => {
				setSuccessSignUp(true);
				_sleep(3000);
				goTo("/verifyaccount");
			})
			.catch((err: any) => {
				actions.setSubmitting(false);
				setErrorSignUp(true);
			});
	}

	useEffect(() => {
		isAuthenticated();
		(window as Window).scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Header
				color="transparent"
				brand="My Cleaner Help"
				rightLinks={<HeaderLinks />}
				fixed
				changeColorOnScroll={{
					height: 200,
					color: "white",
				}}
				{...rest}
			/>
			<Parallax small image="/img/home/cleaning_services.webp">
				<div className={classes.container}>
					<Grid container style={{ zIndex: 19 }}>
						<Grid item xs={12} sm={12} md={12}>
							<h1 className={classes.titleWhite}>Sign Up</h1>
						</Grid>
					</Grid>
				</div>
			</Parallax>
			<div
				className={classNames(
					classes.main,
					classes.mainRaised,
					classes.section
				)}
			>
				<Container>
					<Grid container spacing={2} className={classes.justifyCenter}>
						<Grid item xs={12} sm={12} md={6}>
							<Paper elevation={3}>
								<h1 className={classes.blueText + " " + classes.title}>
									Sign Up
								</h1>
								<h2 className={classes.description}>
									Sign Up to track your Bookings, Reschedule, manage Add-ons,
									and more.
								</h2>
								<Container>
									<Formik
										initialValues={{
											//username: '',
											email: "",
											password: "",
											phone_number: "+44",
										}}
										validationSchema={Yup.object().shape({
											email: Yup.string()
												.email("Must be a valid email")
												.max(50)
												.required("Email is required"),
											password: Yup.string()
												.min(8)
												.max(15)
												.required("Password is required"),
											phone_number: Yup.string()
												.matches(phoneRegExp, "Phone number is not valid (e.g +447715546612)")
												.required("Phone is required"),
										})}
										onSubmit={_handleSubmitForSignUp}
									>
										{({ isSubmitting }) => (
											<Form id="signupForm">
												<Grid container spacing={1}>
													<Grid item xs={12} sm={12}>
														<span className={classes.description}>Email:</span>
														<InputField
															type="text"
															name="email"
															label="Email Address"
															fullWidth
														/>
													</Grid>
													<Grid item xs={12} sm={12}>
														<span className={classes.description}>
															Password:
														</span>
														<InputField
															type="password"
															name="password"
															label="Password"
															fullWidth
														/>
													</Grid>
													<Grid item xs={12} sm={12}>
														<span className={classes.description}>Phone:</span>
														<InputField
															type="text"
															name="phone_number"
															label="Phone"
															fullWidth
														/>
													</Grid>
													<Grid item xs={12} sm={12}>
														<Box className={classes.py10}>
															<Button
																disabled={isSubmitting}
																type="submit"
																variant="contained"
																color="primary"
															>
																Sign Up{" "}
																{isSubmitting && <CircularProgress size={24} />}
															</Button>
														</Box>
													</Grid>
													<Grid item xs={12} sm={12}>
														<span className={classes.description}>
															Already registered?{" "}
															<Link to="/signin">Sign in here</Link>
														</span>
													</Grid>
												</Grid>
											</Form>
										)}
									</Formik>
								</Container>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</div>
			<Snackbar
				open={successSignUp}
				autoHideDuration={6000}
				onClose={() => setSuccessSignUp(false)}
			>
				<Alert onClose={() => setSuccessSignUp(false)} severity="success">
					Signed Up successfully! verify your email address.
				</Alert>
			</Snackbar>
			<Snackbar
				open={errorSignUp}
				autoHideDuration={6000}
				onClose={() => setErrorSignUp(false)}
			>
				<Alert onClose={() => setErrorSignUp(false)} severity="error">
					Error occured in verifing your email address.
				</Alert>
			</Snackbar>

			<Footer />
		</div>
	);
}
