import React, { useEffect, useState } from "react";
import classNames from "classnames";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import {
	Box,
	Container,
	Paper,
	Grid,
	Button,
	Snackbar,
	CircularProgress,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { Helmet } from "react-helmet";
// core components
import HeaderLinks from "components/Header/HeaderLinks";
import Parallax from "components/Parallax/Parallax";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import useStyles from "assets/styles";
import { Auth } from "aws-amplify";

import { InputField } from "../../components/FormFields";
import { $FIXME, LOGIN_SUCCESS } from "Helpers/constants";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "Reducers";
import { envirnoment } from "envirnoment";

export default function SigninPage(props: $FIXME) {
	const classes = useStyles();

	const dispatch = useDispatch();
	const { ...rest } = props;
	const [errorSignIn, setErrorSignIn] = useState(false);
	const auth = useSelector((state: StateType) => state?.auth);
	const redirectLogin = useSelector(
		(state: StateType) => state.auth.redirectLogin
	);

	useEffect(() => {
		(window as Window).scrollTo(0, 0);
	}, []);

	function _handleSubmitForSignUp(values: any, actions: any) {
		let a: any = {};
		a.username = values.username;
		a.password = values.password;

		Auth.signIn(a)
			.then((cognitoUser) => {
				actions.setSubmitting(false);
				dispatch({ type: LOGIN_SUCCESS, payload: cognitoUser });
			})
			.catch((err) => {
				setErrorSignIn(true);
				actions.setSubmitting(false);
			});
	}

	async function fbLogin() {
		let res = await Auth.federatedSignIn();
	}

	if (redirectLogin) {
		if (auth.group === "Customer")
			return <Navigate to="/dashboard/cs/bookings" />;
		else return <Navigate to="/dashboard" />;
	}

	return (
		<div>
			<Helmet>
				<base />
				<title>Login and Get The Best Cleaning Services</title>
				<meta
					name="description"
					content="Sign in To Track your Booking Cleaner. Reschedule Your Cleaner. Manage Add-ons Your Cleaners, and more"
				/>
				<link rel="canonical" href={envirnoment.websiteURL + "/signin"} />
			</Helmet>
			<Header
				color="transparent"
				brand="My Cleaner Help"
				rightLinks={<HeaderLinks />}
				fixed
				changeColorOnScroll={{
					height: 200,
					color: "white",
				}}
				{...rest}
			/>
			<Parallax small image="/img/home/cleaning_services.webp">
				<div className={classes.container}>
					<Grid container style={{ zIndex: 19 }}>
						<Grid item xs={12} sm={12} md={12}>
							<h1 className={classes.titleWhite}>Sign In</h1>
							<h2 className={classes.description} style={{ color: "#fff" }}>
								Sign in to track your Booking, Reschedule, manage Add-ons, and
								more
							</h2>
						</Grid>
					</Grid>
				</div>
			</Parallax>
			<div
				className={classNames(
					classes.main,
					classes.mainRaised,
					classes.section
				)}
			>
				<Container>
					<div className={classNames(classes.justifyCenter, classes.section)}>
						<Grid
							container
							spacing={2}
							className={classNames(classes.justifyCenter, classes.section)}
							style={{ margin: "100" }}
						>
							<Grid item xs={12} sm={12} md={6}>
								<Paper elevation={4} className={classes.p25}>
									<h2 className={classes.blueText + " " + classes.title}>
										Sign In
									</h2>
									<Container>
										<Formik
											initialValues={{
												username: "",
												password: "",
											}}
											validationSchema={Yup.object().shape({
												username: Yup.string()
													.email("Must be a valid email")
													.max(50)
													.required("Email is required"),
												password: Yup.string()
													.min(8)
													.max(15)
													.required("Password is required"),
											})}
											onSubmit={_handleSubmitForSignUp}
										>
											{({ isSubmitting }) => (
												<Form id="signinForm">
													<Grid container spacing={1}>
														<Grid item xs={12} sm={12}>
															<span className={classes.description}>
																Email:
															</span>
															<InputField
																type="text"
																name="username"
																label="Email"
																fullWidth
															/>
														</Grid>
														<Grid item xs={12} sm={12}>
															<span className={classes.description}>
																Password:
															</span>
															<InputField
																type="password"
																name="password"
																label="Password"
																fullWidth
															/>
														</Grid>
														<Grid item xs={12} sm={12}>
															<Box className={classes.py10}>
																<Button
																	disabled={isSubmitting}
																	type="submit"
																	variant="contained"
																	color="primary"
																>
																	Sign In{" "}
																	{isSubmitting && (
																		<CircularProgress size={24} />
																	)}
																</Button>
															</Box>
														</Grid>
														<Grid item xs={12} sm={12}>
															<Button
																type="button"
																onClick={fbLogin}
																variant="contained"
																style={{
																	backgroundColor: "#3B5998",
																	color: "white",
																}}
															>
																<i
																	className={"fab fa-facebook"}
																	style={{ marginRight: 5 }}
																></i>
																SignIn with Facebook
															</Button>
														</Grid>
														<Grid item xs={12} sm={12}>
															<span className={classes.description}>
																Don't have an account?{" "}
																<Link to="/signup">Sign Up</Link> now, it takes
																just a few seconds. Or
																<Link to="/forgotpassword">
																	{" "}
																	Forgot your password?
																</Link>
															</span>
														</Grid>
													</Grid>
												</Form>
											)}
										</Formik>
									</Container>
								</Paper>
							</Grid>
						</Grid>
					</div>
				</Container>
			</div>

			<Snackbar
				open={errorSignIn}
				autoHideDuration={6000}
				onClose={() => setErrorSignIn(false)}
			>
				<Alert onClose={() => setErrorSignIn(false)} severity="error">
					Email or password is incorrect!
				</Alert>
			</Snackbar>
			<Footer />
		</div>
	);
}
