import thunk from "redux-thunk";
import { buildReducers } from "Reducers";
import { createReduxHistoryContext } from "redux-first-history";
import { routeHistory } from "../routeHistory";

const { createReduxHistory, routerMiddleware, routerReducer } =
	createReduxHistoryContext({
		history: routeHistory,
		// savePreviousLocations: 1,
	});

export const store = buildReducers(routerReducer, [routerMiddleware, thunk]);
export const history = createReduxHistory(store);
