import axios from "axios";
import { envirnoment } from "envirnoment";

export const PUBLICAPI = axios.create({
	baseURL: envirnoment.graphqlAPI,
	headers: {
		"x-api-key": envirnoment.graphqlAPIKey,
	},
});

export const LAMBDA_API = axios.create({
	baseURL: envirnoment.lambdaAPI,
});
