// import * as React from "react";
// import { useEffect } from "react";
import { Auth } from "aws-amplify";

//
// interface PrivateRouteProps extends RouteProps {
// 	component: React.FunctionComponent<RouteProps>;
// }
//
// const PrivateRoute = (props: PrivateRouteProps) => {
// 	const { component: Component, ...rest } = props;
//
// 	const isAuthenticated = () => {
// 		Auth.currentSession()
// 			.then((response) => {
// 				if (!response.isValid()) redirectToLogin();
// 			})
// 			.catch(() => {
// 				redirectToLogin();
// 			});
// 	};
//
// 	const redirectToLogin = () => {
// 		goTo("/signin");
// 	};
//
// 	useEffect(() => {
// 		isAuthenticated();
// 	}, []);
//
// 	return <Route {...rest} render={(props) => <Component {...props} />} />;
// };
//
// export default PrivateRoute;
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { StateType } from "../Reducers";
import { goTo } from '../routeHistory';

const PrivateRoutes = () => {
	const isAuthenticated = () => {
		Auth.currentSession()
			.then((response) => {
				if (!response.isValid()) redirectToLogin();
			})
			.catch(() => {
				redirectToLogin();
			});
	};

	const redirectToLogin = () => {
		goTo("/signin");
	};

	useEffect(() => {
		isAuthenticated();
	}, []);

	const authenticated = useSelector(
		(state: StateType) => state.auth.authenticated
	);

	return authenticated ? <Outlet /> : <Navigate to={"/signin"} />;
};

export default PrivateRoutes;
