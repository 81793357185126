import React from "react";
import at from "lodash.at";
import { useField } from "formik";
import useStyles from "assets/styles";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
} from "@mui/material";

export default function CheckboxField(props: any) {
	const classes = useStyles();
	const { label, ...rest } = props;
	const [field, meta, helper] = useField(props);
	const { setValue } = helper;

	function _renderHelperText() {
		const [touched, error] = at(meta, "touched", "error");
		if (touched && error) {
			return <FormHelperText>{error}</FormHelperText>;
		}
	}

	function _onChange(e: any) {
		setValue(e.target.checked);
	}

	return (
		<FormControl {...rest}>
			<FormControlLabel
				style={{ border: "1px solid rgba(0, 0, 0, 0.24)" }}
				className={field.value ? classes.selectedService : ""}
				value={field.value}
				checked={field.value}
				control={
					<Checkbox
						style={{ display: "none" }}
						checked={field.value}
						{...field}
						onChange={_onChange}
					/>
				}
				label={label}
				{...rest}
			/>
			{field.checked}
			{_renderHelperText()}
		</FormControl>
	);
}
