import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store, history } from "Helpers/store";
import { HistoryRouter as BrowserRouter } from "redux-first-history/rr6";
import Amplify from "@aws-amplify/core";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter history={history}>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
