import React from "react";
import at from "lodash.at";
import { useField } from "formik";
import {
	InputLabel,
	FormControl,
	Select,
	MenuItem,
	FormHelperText,
} from "@mui/material";

interface SelectFieldProp {
	data: any[];
	label: string;
	name: string;
	fullWidth?: boolean;
	disabled?: boolean;
	[x: string]: any;
}

function SelectField(props: SelectFieldProp) {
	const [field, meta] = useField(props);
	const { value: selectedValue } = field;
	const [touched, error] = at(meta, "touched", "error");
	const isError = touched && error && true;
	function _renderHelperText() {
		if (isError) {
			return <FormHelperText>{error}</FormHelperText>;
		}
	}

	return (
		<FormControl
			{...props.x}
			style={{ zIndex: 1 }}
			variant="outlined"
			fullWidth
			disabled={props.disabled}
			error={isError}
		>
			<InputLabel
				disabled={props.disabled}
				style={{
					zIndex: 2,
					backgroundColor: "#fff",
					paddingRight: "2px",
					paddingLeft: "2px",
				}}
			>
				{props.label}
			</InputLabel>
			<Select
				{...props.x}
				{...field}
				disabled={props.disabled}
				value={selectedValue ? selectedValue : ""}
			>
				{props.data.map((item: any, index: any) => (
					<MenuItem key={index} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
			{_renderHelperText()}
		</FormControl>
	);
}

export default SelectField;
