import React from "react";
import at from "lodash.at";
import { useField } from "formik";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

export default function CardField(props: any) {
	const { label, ...rest } = props;
	const [field, meta, helper] = useField(props);
	const { setValue } = helper;

	function _renderHelperText() {
		const [touched, error] = at(meta, "touched", "error");
		if (touched && error) {
			return <FormHelperText>{error}</FormHelperText>;
		}
	}

	function _onChange(e: any) {
		setValue(e.target.checked);
	}

	return (
		<FormControl {...rest}>
			<FormControlLabel
				value={field.value}
				checked={field.value}
				control={
					<Checkbox checked={field.value} {...field} onChange={_onChange} />
				}
				label={label}
				{...rest}
			/>
			{field.checked}
			{_renderHelperText()}
		</FormControl>
	);
}
