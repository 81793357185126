import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const drawerWidth = 260;

const transition = {
	transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const containerFluid = {
	paddingRight: "7px",
	paddingLeft: "7px",
	marginRight: "auto",
	marginLeft: "auto",
	width: "100%",
};
const container = {
	...containerFluid,
	"@media (min-width: 576px)": {
		maxWidth: "580px",
	},
	"@media (min-width: 768px)": {
		maxWidth: "760px",
	},
	"@media (min-width: 992px)": {
		maxWidth: "1000px",
	},
	"@media (min-width: 1200px)": {
		maxWidth: "1180px",
	},
	"@media (min-width: 1500px)": {
		maxWidth: "1440px",
	},
	"@media (min-width: 1800px)": {
		maxWidth: "1740px",
	},
	"@media (min-width: 2200px)": {
		maxWidth: "2140px",
	},
	"@media (min-width: 2400px)": {
		maxWidth: "2340px",
	},
};

const boxShadow = {
	boxShadow:
		"0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
};

const card = {
	display: "inline-block",
	position: "relative",
	width: "100%",
	margin: "25px 0",
	boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
	borderRadius: "3px",
	color: "rgba(0, 0, 0, 0.87)",
	background: "#fff",
};

const defaultFont = {
	fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	fontWeight: 700,
	lineHeight: "1.5em",
};

const primaryColor = "#9c27b0";
const warningColor = "#ff9800";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "#242424";

const primaryBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)",
};
const infoBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
};
const successBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)",
};
const warningBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
};
const dangerBoxShadow = {
	boxShadow:
		"0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
};
const roseBoxShadow = {
	boxShadow:
		"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
};

const warningCardHeader = {
	color: "#fff",
	background: "linear-gradient(60deg, #ffa726, #fb8c00)",
	...warningBoxShadow,
};
const successCardHeader = {
	color: "#fff",
	background: "linear-gradient(60deg, #66bb6a, #43a047)",
	...successBoxShadow,
};
const dangerCardHeader = {
	color: "#fff",
	background: "linear-gradient(60deg, #ef5350, #e53935)",
	...dangerBoxShadow,
};
const infoCardHeader = {
	color: "#fff",
	background: "linear-gradient(60deg, #26c6da, #00acc1)",
	...infoBoxShadow,
};
const primaryCardHeader = {
	color: "#fff",
	background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
	...primaryBoxShadow,
};
const roseCardHeader = {
	color: "#fff",
	background: "linear-gradient(60deg, #ec407a, #d81b60)",
	...roseBoxShadow,
};
const cardActions = {
	margin: "0 20px 10px",
	paddingTop: "10px",
	borderTop: "1px solid #eeeeee",
	height: "auto",
	...defaultFont,
};

const cardHeader = {
	margin: "-30px 15px 0",
	borderRadius: "3px",
	padding: "15px",
};

const defaultBoxShadow = {
	border: "0",
	borderRadius: "3px",
	boxShadow:
		"0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
	padding: "10px 0",
	transition: "all 150ms ease 0s",
};

const title = {
	color: "#3C4858",
	margin: "1.75rem 0 0.875rem",
	textDecoration: "none",
	fontWeight: 700,
	fontFamily: `"Roboto Slab", "Times New Roman", serif`,
};

const titleAndrey = {
	fontSize: "1.5rem",
};

const cardTitle = {
	...title,
	marginTop: ".625rem",
};

const cardLink = {
	"& + $cardLink": {
		marginLeft: "1.25rem",
	},
};

const cardSubtitle = {
	marginBottom: "0",
	marginTop: "-.375rem",
};

export {
	//variables
	drawerWidth,
	transition,
	container,
	containerFluid,
	boxShadow,
	card,
	defaultFont,
	primaryColor,
	warningColor,
	dangerColor,
	successColor,
	infoColor,
	roseColor,
	grayColor,
	primaryBoxShadow,
	infoBoxShadow,
	successBoxShadow,
	warningBoxShadow,
	dangerBoxShadow,
	roseBoxShadow,
	warningCardHeader,
	successCardHeader,
	dangerCardHeader,
	infoCardHeader,
	primaryCardHeader,
	roseCardHeader,
	cardActions,
	cardHeader,
	defaultBoxShadow,
	title,
	titleAndrey,
	cardTitle,
	cardLink,
	cardSubtitle,
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		section: {
			padding: "20px 0",
		},
		py10: {
			padding: "10px 0",
		},
		p10: {
			padding: "10px",
		},
		p111: {
			width: "100%",
			height: "100%",
			padding: "0 8px",
		},
		p25: {
			padding: "25px",
		},

		button: {
			background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
			border: 0,
			borderRadius: 3,
			boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
			color: "white",
			height: 48,
			padding: "0 30px",
			cursor: "pointer",
		},
		title: {
			color: "#3C4858",
			margin: "1.75rem 0 0.875rem",
			textDecoration: "none",
			fontWeight: 700,
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
			marginBottom: ".5rem",
			marginTop: "30px",
			minHeight: "32px",
			fontSize: "1.3rem",
		},
		titleAndrey: {
			fontSize: "1.5rem",
			paddingBottom: "0.6rem",
			display: "flex",
		},
		titleWhite: {
			color: "#fff",
			margin: "1.75rem 0 0.875rem",
			textDecoration: "none",
			fontWeight: 700,
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
			marginBottom: ".5rem",
			marginTop: "30px",
			minHeight: "32px",
		},
		imgFluid: {
			maxWidth: "100%",
			height: "auto",
		},
		imgRounded: {
			borderRadius: "6px !important",
		},
		imgRoundedCircle: {
			borderRadius: "50% !important",
		},
		imgRaised: {
			boxShadow:
				"0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
		},
		imgGallery: {
			width: "100%",
			marginBottom: "2.142rem",
		},
		imgCardTop: {
			width: "100%",
			borderTopLeftRadius: "calc(.25rem - 1px)",
			borderTopRightRadius: "calc(.25rem - 1px)",
		},
		imgCardBottom: {
			width: "100%",
			borderBottomLeftRadius: "calc(.25rem - 1px)",
			borderBottomRightRadius: "calc(.25rem - 1px)",
		},
		imgCard: {
			width: "100%",
			borderRadius: "calc(.25rem - 1px)",
		},
		imgCardOverlay: {
			position: "absolute",
			top: "0",
			right: "0",
			bottom: "0",
			left: "0",
			padding: "1.25rem",
		},
		itemGrid: {
			marginLeft: "auto",
			marginRight: "auto",
		},
		cardTitle: {
			color: "#3C4858",
			margin: "1.75rem 0 0.875rem",
			textDecoration: "none",
			fontWeight: 700,
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
			textAlign: "center",
		},
		smallTitle: {
			color: "#6c757d",
		},
		description: {
			fontSize: "16px",
			color: "#202020",
			textDecoration: "none",
			fontWeight: 400,
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,

			"@media (min-width: 1700px)": {
				fontSize: "18px",
			},
			"@media (min-width: 1950px)": {
				fontSize: "20px",
			},
			"@media (min-width: 2250px)": {
				fontSize: "22px",
			},
		},
		summaryDescription: {
			fontSize: "14px",
			color: "#202020",
			textDecoration: "none",
			fontWeight: 400,
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
		},
		graySmallText: {
			marginTop: 0,
			fontSize: "13px",
			color: "#a0a0a0",
			textDecoration: "none",
			fontWeight: 400,
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
		},
		summaryExtraServices: {
			fontSize: "13px",
			display: "block",
			color: "#202020",
			textDecoration: "none",
			fontWeight: 400,
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
		},
		lineThroughPrice: {
			fontSize: "13px",
			color: "#202020",
			textDecoration: "line-through",
			fontWeight: 400,
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
		},
		justifyMiddle: {
			paddingTop: "30px",
			paddingBottom: "3rem",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			margin: "0 auto",
			marginTop: "35px",
			width: "fit-content",
		},

		summaryPadding: {
			paddingTop: "2.rem",
			marginTop: "2.rem",
			padding: "0 auto",
		},
		flexi: {
			display: "flex",
			flexDirection: "column",
			"@media (min-width: 676px)": {
				flexDirection: "row",
			},
		},
		justifyCenter: {
			justifyContent: "center",
			alignItems: "flex",
			textAlign: "center",
			width: "100%",
			margin: "0",
			padding: "0",
			marginRight: "0",
			marginLeft: "0",
		},
		flexA: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
		},
		justifyCenter2: {
			// width 100vw
			justifyContent: "center",
			alignItems: "flex",
			textAlign: "center",
			width: "100%",
			margin: "0",
			padding: "0",
		},
		justifyCenter3: {
			// width 100vw
			paddingTop: "25px",
			justifyContent: "center",
			alignItems: "flex",
			textAlign: "center",
			width: "100%",
			padding: "0",
		},
		socials: {
			marginTop: "0",
			width: "100%",
			transform: "none",
			left: "0",
			top: "0",
			height: "100%",
			lineHeight: "41px",
			fontSize: "20px",
			color: "#999",
		},
		margin5: {
			margin: "5px",
		},
		descriptionLeftAlign: {
			color: "#242424",
			textAlign: "left",
		},
		name: {
			marginTop: "-80px",
		},
		main: {
			background: "#FFFFFF",
			position: "relative",
			zIndex: 3,
			color: "#242424",
		},
		titleAlignCenter: {
			textAlign: "center",
		},
		navWrapper: {
			margin: "20px auto 50px auto",
			textAlign: "center",
		},
		yellowContainer: {
			backgroundColor: "#ffe054",
			padding: "20px",
			marginTop: "50px",
		},
		blueText: {
			color: "#1d7abf",
			fontWeight: 700,
			textDecoration: "none",
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
		},
		whiteText: {
			color: "#fff",
			fontWeight: 700,
			textDecoration: "none",
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
		},
		listStyle: {
			fontSize: "16px",
			color: "#202020",
			textDecoration: "none",
			fontWeight: 400,
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
			listStyleImage: "url(treehouse-marker.png)",
		},
		label: {
			cursor: "pointer",
			paddingLeft: "0",
			color: "rgba(0, 0, 0, 0.60)",
			fontSize: "14px",
			lineHeight: "1.428571429",
			fontWeight: 400,
			display: "inline-flex",
			textAlign: "left",
		},
		marginTop10px: {
			marginTop: "10px",
		},
		marginTopAndrey: {
			width: "44%",
			// backgroundColor: "green",
			margin: "5px auto",
		},
		sectionForm: {
			padding: "10px 0 20px 0",
			textDecoration: "none",
			textAlign: "center",
		},

		form: {
			margin: "0 auto",
			width: "100%",
			maxWidth: "700px",
			padding: "0 20px",
			boxSizing: "border-box",
			border: "1px solid #e0e0e0",
			borderRadius: "4px",
			backgroundColor: "#fff",
			shadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
		},

		subtitle: {
			fontSize: "1.313rem",
			maxWidth: "500px",
			margin: "10px auto 0",
		},
		mainRaised: {
			margin: "-60px 30px 0px",
			borderRadius: "6px",
			boxShadow:
				"0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
		},
		mainAndrey: {
			margin: "-60px 0px 0px",
			"@media (min-width: 400px)": {
				margin: "-60px 0px 0px",
			},
		},
		stepperContainer: {
			width: "auto!important",
		},
		stepperHorizontal: {
			alignItems: "center",
		},
		stepperVertical: {
			alignItems: "baseline",
		},
		stepperStepHorizontalText: {
			flexDirection: "row",
			gap: "0px",
		},
		stepperStepVerticalText: {
			flexDirection: "column",
			gap: "8px",
		},
		container: {
			paddingRight: "15px",
			paddingLeft: "15px",
			marginRight: "auto",
			marginLeft: "auto",
			width: "100%",
			zIndex: 12,
			"@media (min-width: 576px)": {
				maxWidth: "540px",
			},
			"@media (min-width: 768px)": {
				maxWidth: "720px",
			},
			"@media (min-width: 992px)": {
				maxWidth: "960px",
			},
			"@media (min-width: 1200px)": {
				maxWidth: "1140px",
			},
			"@media (min-width: 1500px)": {
				maxWidth: "1440px",
			},
			"@media (min-width: 1800px)": {
				maxWidth: "1740px",
			},
			"@media (min-width: 2200px)": {
				maxWidth: "2140px",
			},
			"@media (min-width: 2400px)": {
				maxWidth: "2340px",
			},
		},
		profile: {
			textAlign: "center",
			"& img": {
				maxWidth: "160px",
				width: "100%",
				margin: "0 auto",
				transform: "translate3d(0, -50%, 0)",
			},
		},
		block: {
			color: "#fff",
			fontWeight: 400,
			fontSize: "12px",
			textTransform: "uppercase",
			borderRadius: "3px",
			textDecoration: "none",
			position: "relative",
			display: "block",
		},
		left: {
			float: "left",
			display: "block",
		},
		right: {
			padding: "15px 0",
			margin: "0",
			float: "right",
			fontSize: "12px",
			color: "#242424",
		},
		rightText: {
			margin: "0",
			float: "right",
		},
		footer: {
			color: "#fff",
			padding: "40px 0",
			textAlign: "center",
			display: "flex",
			position: "relative",
			textDecoration: "none",
			fontWeight: 400,
			fontSize: "14px",
			fontFamily: `"Roboto Slab", "Times New Roman", serif`,
			backgroundColor: "#242424",
		},
		a: {
			color: primaryColor,
			textDecoration: "none",
			backgroundColor: "transparent",
		},
		footerWhiteFont: {
			"&,&:hover,&:focus": {
				color: "#FFFFFF",
			},
		},
		list: {
			marginBottom: "0",
			padding: "0",
			marginTop: "0",
		},
		inlineBlock: {
			display: "inline-block",
			padding: "0px",
			width: "auto",
		},
		icon: {
			width: "18px",
			height: "18px",
			position: "relative",
			top: "3px",
		},

		appBar: {
			display: "flex",
			border: "0",
			borderRadius: "3px",
			padding: "0.625rem 0",
			marginBottom: "20px",
			color: "#555",
			width: "100%",
			backgroundColor: "#fff",
			boxShadow:
				"0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
			transition: "all 150ms ease 0s",
			alignItems: "center",
			flexFlow: "row nowrap",
			justifyContent: "flex-start",
			position: "relative",
			zIndex: "unset",
		},
		absolute: {
			position: "absolute",
			zIndex: 1100,
		},
		fixed: {
			position: "fixed",
			zIndex: 1100,
		},
		containerHeader: {
			...container,
			minHeight: "50px",
			flex: "1",
			alignItems: "center",
			justifyContent: "space-between",
			display: "flex",
			flexWrap: "nowrap",
		},
		flex: {
			flex: 1,
		},
		titleHeader: {
			lineHeight: "25px",
			fontSize: "20px",
			borderRadius: "3px",
			textTransform: "none",
			color: "inherit",
			padding: "5px 16px",
			letterSpacing: "unset",
			"&:hover,&:focus": {
				color: "inherit",
				background: "transparent",
			},
		},
		appResponsive: {
			margin: "20px 10px",
		},
		primary: {
			backgroundColor: primaryColor,
			color: "#FFFFFF",
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)",
		},
		info: {
			backgroundColor: infoColor,
			color: "#FFFFFF",
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)",
		},
		success: {
			backgroundColor: successColor,
			color: "#FFFFFF",
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)",
		},
		warning: {
			backgroundColor: warningColor,
			color: "#FFFFFF",
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)",
		},
		danger: {
			backgroundColor: dangerColor,
			color: "#FFFFFF",
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)",
		},
		rose: {
			backgroundColor: roseColor,
			color: "#FFFFFF",
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)",
		},
		transparent: {
			backgroundColor: "transparent !important",
			boxShadow: "none",
			paddingTop: "25px",
			color: "#FFFFFF",
		},
		transparentBlackText: {
			backgroundColor: "transparent !important",
			boxShadow: "none",
			paddingTop: "25px",
			color: "#555",
		},
		dark: {
			color: "#FFFFFF",
			backgroundColor: "#212121 !important",
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)",
		},
		stepBlock:{
			color: "#fff",
			backgroundColor: "gray",
			padding: "5px 10px",
			borderRadius: 50,
		},
		iconBackground:{
			color: "#fff",
			backgroundColor: "gray",
			padding: "5px 5px",
			borderRadius: 50,
		},
		centeredElement: {
			margin: 0,
			position: "absolute",
			top: "50%",
		},
		white: {
			border: "0",
			padding: "0.625rem 0",
			marginBottom: "20px",
			color: "#555",
			backgroundColor: "#fff !important",
			boxShadow:
				"0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
		},
		drawerPaper: {
			border: "none",
			bottom: "0",
			transitionProperty: "top, bottom, width",
			transitionDuration: ".2s, .2s, .35s",
			transitionTimingFunction: "linear, linear, ease",
			width: drawerWidth,
			...boxShadow,
			position: "fixed",
			display: "block",
			top: "0",
			height: "100vh",
			right: "0",
			left: "auto",
			visibility: "visible",
			overflowY: "visible",
			borderTop: "none",
			textAlign: "left",
			paddingRight: "0px",
			paddingLeft: "0",
			...transition,
		},
		listNav: {
			...defaultFont,
			fontSize: "14px",
			margin: 0,
			paddingLeft: "0",
			listStyle: "none",
			paddingTop: "0",
			paddingBottom: "0",
			color: "inherit",
		},
		listItem: {
			float: "left",
			color: "inherit",
			position: "relative",
			display: "inline-flex",
			width: "auto",
			margin: "0",
			padding: "0",
			// [theme.breakpoints.down("sm")]: {
			//   width: "100%",
			//   "&:after": {
			//     width: "calc(100% - 30px)",
			//     content: '""',
			//     display: "block",
			//     height: "1px",
			//     marginLeft: "15px",
			//     backgroundColor: "#e5e5e5"
			//   }
			// }
		},
		listItemText: {
			padding: "0 !important",
		},
		navLink: {
			color: "inherit",
			position: "relative",
			padding: "0.9375rem",
			fontWeight: 400,
			fontSize: "12px",
			textTransform: "uppercase",
			borderRadius: "3px",
			lineHeight: "20px",
			textDecoration: "none",
			margin: "0px",
			display: "inline-flex",
			"&:hover,&:focus": {
				color: "inherit",
				background: "rgba(200, 200, 200, 0.2)",
			},
			[theme.breakpoints.down("sm")]: {
				width: "calc(100% - 30px)",
				marginLeft: "15px",
				marginBottom: "8px",
				marginTop: "8px",
				textAlign: "left",
				"& > span:first-child": {
					justifyContent: "flex-start",
				},
			},
		},
		notificationNavLink: {
			color: "inherit",
			padding: "0.9375rem",
			fontWeight: 400,
			fontSize: "12px",
			textTransform: "uppercase",
			lineHeight: "20px",
			textDecoration: "none",
			margin: "0px",
			display: "inline-flex",
			top: "4px",
		},
		registerNavLink: {
			top: "3px",
			position: "relative",
			fontWeight: 400,
			fontSize: "12px",
			textTransform: "uppercase",
			lineHeight: "20px",
			textDecoration: "none",
			margin: "0px",
			display: "inline-flex",
		},
		navLinkActive: {
			color: "inherit",
			backgroundColor: "rgba(255, 255, 255, 0.1)",
		},
		icons: {
			width: "20px",
			height: "20px",
			marginRight: "3px",
		},
		socialIcons: {
			position: "relative",
			fontSize: "20px !important",
			marginRight: "4px",
		},
		dropdownLink: {
			"&,&:hover,&:focus": {
				color: "inherit",
				textDecoration: "none",
				display: "block",
				padding: "10px 10px",
			},
		},
		tooltip: {
			padding: "10px 15px",
			minWidth: "130px",
			color: "#555555",
			lineHeight: "1.7em",
			background: "#FFFFFF",
			border: "none",
			borderRadius: "3px",
			boxShadow:
				"0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
			maxWidth: "200px",
			textAlign: "center",
			fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
			fontSize: "0.875em",
			fontStyle: "normal",
			fontWeight: 400,
			textShadow: "none",
			textTransform: "none",
			letterSpacing: "normal",
			wordBreak: "normal",
			wordSpacing: "normal",
			wordWrap: "normal",
			whiteSpace: "normal",
			lineBreak: "auto",
		},
		marginRight5: {
			marginRight: "5px",
		},
		card: {
			border: "0",
			marginBottom: "30px",
			marginTop: "30px",
			borderRadius: "6px",
			color: "rgba(0, 0, 0, 0.87)",
			background: "#fff",
			width: "100%",
			boxShadow:
				"0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
			position: "relative",
			display: "flex",
			flexDirection: "column",
			minWidth: "0",
			wordWrap: "break-word",
			fontSize: ".875rem",
			transition: "all 300ms linear",
		},
		cardPlain: {
			background: "transparent",
			boxShadow: "none",
		},
		cardCarousel: {
			overflow: "hidden",
		},
		cardBody: {
			padding: "0.9375rem 1.875rem",
			flex: "1 1 auto",
		},
		cardFooter: {
			display: "flex",
			alignItems: "center",
			backgroundColor: "transparent",
			padding: "0.9375rem 1.875rem",
		},
		cardHeader: {
			borderRadius: "3px",
			padding: "1rem 15px",
			marginLeft: "15px",
			marginRight: "15px",
			marginTop: "-30px",
			border: "0",
			marginBottom: "0",
		},
		cardHeaderPlain: {
			marginLeft: "0px",
			marginRight: "0px",
		},
		warningCardHeader,
		successCardHeader,
		dangerCardHeader,
		infoCardHeader,
		primaryCardHeader,
		infoArea: {
			maxWidth: "360px",
			margin: "0 auto",
			padding: "0px",
		},
		iconWrapper: {
			float: "left",
			marginTop: "24px",
			marginRight: "10px",
		},
		gray: {
			color: grayColor,
		},
		descriptionWrapper: {
			color: grayColor,
			overflow: "hidden",
		},
		iconWrapperVertical: {
			float: "none",
		},
		iconVertical: {
			width: "61px",
			height: "61px",
		},
		imageWrapper: {
			float: "left",
			marginTop: "24px",
			marginRight: "10px",
		},
		imageWrapperImg: {
			width: "100%",
			height: "220px",
		},
		disabled: {
			opacity: "0.65",
			pointerEvents: "none",
		},
		lg: {
			padding: "1.125rem 2.25rem",
			fontSize: "0.875rem",
			lineHeight: "1.333333",
			borderRadius: "0.2rem",
		},
		sm: {
			padding: "0.40625rem 1.25rem",
			fontSize: "0.6875rem",
			lineHeight: "1.5",
			borderRadius: "0.2rem",
		},
		round: {
			borderRadius: "30px",
		},
		link: {
			"&,&:hover,&:focus": {
				backgroundColor: "transparent",
				color: "#999999",
				boxShadow: "none",
			},
		},
		linkTitle: {
			"&:hover": {
				color: "#264556",
				textDecoration: "none",
			},
		},
		justIcon: {
			paddingLeft: "12px",
			paddingRight: "12px",
			fontSize: "20px",
			height: "41px",
			minWidth: "41px",
			width: "41px",
			"& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
				marginRight: "0px",
			},
			"&$lg": {
				height: "57px",
				minWidth: "57px",
				width: "57px",
				lineHeight: "56px",
				"& .fab,& .fas,& .far,& .fal,& .material-icons": {
					fontSize: "32px",
					lineHeight: "56px",
				},
				"& svg": {
					width: "32px",
					height: "32px",
				},
			},
			"&$sm": {
				height: "30px",
				minWidth: "30px",
				width: "30px",
				"& .fab,& .fas,& .far,& .fal,& .material-icons": {
					fontSize: "17px",
					lineHeight: "29px",
				},
				"& svg": {
					width: "17px",
					height: "17px",
				},
			},
		},
		selectedService: {
			backgroundColor: "#ffe054",
			borderRadius: "6px !important",
		},
		bottomBar: {
			boxSizing: "border-box",
			position: "fixed",
			bottom: "0",
			width: "100%",
			zIndex: 13,
			backgroundColor: "#0799C7",
			"@media (min-width: 576px)": {
				display: "block",
			},
			"@media (min-width: 577px)": {
				display: "none",
			},
		},
		bookNowButton: {
			...defaultFont,
			boxSizing: "border-box",
			display: "inline-block",
			border: "1px solid #c1c1c1",
			padding: "0",
			width: "50%",
			height: "100%",
			minHeight: "50px",
			lineHeight: "50px",
			verticalAlign: "middle",
			textAlign: "center",
			color: "#ffffff",
			cursor: "pointer",
		},
	})
);

export default useStyles;
