/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// @mui/material components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import useStyles from "assets/styles";
import { $FIXME } from "Helpers/constants";
import { useSelector } from "react-redux";
import { StateType } from "Reducers";

export default function HeaderLinks(props: $FIXME) {
	const classes = useStyles();

	const redirectLogin = useSelector(
		(state: StateType) => state.auth.redirectLogin
	);

	return (
		<List className={classes.listNav}>
			<ListItem className={classes.listItem} style={{ width: "auto" }}>
				<Link to="/" className={classes.navLink}>
					Home
				</Link>
			</ListItem>

			<ListItem className={classes.listItem} style={{ width: "auto" }}>
				<Link to="/about-us" className={classes.navLink}>
					About US
				</Link>
			</ListItem>

			<ListItem className={classes.listItem} style={{ width: "auto" }}>
				<Link to="/cleaning-services" className={classes.navLink}>
					Services
				</Link>
			</ListItem>

			<ListItem className={classes.listItem} style={{ width: "auto" }}>
				<Link to="/giftcard" className={classes.navLink}>
					Gift Cards
				</Link>
			</ListItem>
			<ListItem className={classes.listItem} style={{ width: "auto" }}>
				<Link to="/end-of-tenancy-cleaning" className={classes.navLink}>
					Offers
				</Link>
			</ListItem>
			{!redirectLogin ? (
				<>
					<ListItem className={classes.listItem} style={{ width: "auto" }}>
						<Link to="/signin" className={classes.navLink}>
							SignIn
						</Link>
					</ListItem>
				</>
			) : (
				<>
					<ListItem className={classes.listItem} style={{ width: "auto" }}>
						<Link to="/dashboard" className={classes.navLink}>
							Dashboard
						</Link>
					</ListItem>
				</>
			)}
		</List>
	);
}
