import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/material components
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
// @mui/icons-material
import Menu from "@mui/icons-material/Menu";
import useStyles from "assets/styles";
import { Link } from "react-router-dom";

interface HeaderProps {
	color?: string;
	rightLinks?: React.ReactNode;
	leftLinks?: React.ReactNode;
	brand?: string;
	fixed?: boolean;
	absolute?: boolean;
	changeColorOnScroll: {
		height: number;
		color: string;
	};
}

export default function Header(props: HeaderProps) {
	const classes = useStyles();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	React.useEffect(() => {
		if (props.changeColorOnScroll) {
			window.addEventListener("scroll", headerColorChange);
		}
		return function cleanup() {
			if (props.changeColorOnScroll) {
				window.removeEventListener("scroll", headerColorChange);
			}
		};
	});
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const headerColorChange = () => {
		const { color, changeColorOnScroll } = props;
		const windowsScrollTop = window.pageYOffset;
		if (windowsScrollTop > changeColorOnScroll.height) {
			if (color === "transparentBlackText")
				document.body
					.getElementsByTagName("header")[0]
					.classList.remove(classes.transparentBlackText);
			else
				document.body
					.getElementsByTagName("header")[0]
					.classList.remove(classes.transparent);
			document.body
				.getElementsByTagName("header")[0]
				.classList.add(classes.white);
		} else {
			if (color === "transparentBlackText")
				document.body
					.getElementsByTagName("header")[0]
					.classList.add(classes.transparentBlackText);
			else
				document.body
					.getElementsByTagName("header")[0]
					.classList.add(classes.transparent);
			document.body
				.getElementsByTagName("header")[0]
				.classList.remove(classes.white);
		}
	};
	const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
	const appBarClasses = classNames({
		[classes.appBar]: true,
		//[classes[color]]: color,
		[classes.absolute]: absolute,
		[classes.fixed]: fixed,
	});
	const brandComponent = (
		<Button className={classes.titleHeader}>{brand}</Button>
	);
	return (
		<AppBar className={appBarClasses}>
			<Toolbar className={classes.containerHeader}>
				{leftLinks !== undefined ? brandComponent : null}
				<div className={classes.flex}>
					{leftLinks !== undefined ? (
						<Hidden smDown implementation="css">
							{leftLinks}
						</Hidden>
					) : (
						// brandComponent

							<img
								width="80px"
								height="80px"
								src="/img/mycleanerhelp-logo.webp"
								alt="my cleaner help"
							/>

					)}
				</div>
				<Hidden smDown implementation="css">
					{rightLinks}
				</Hidden>
				<Hidden mdUp>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerToggle}
					>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
			<Hidden mdUp implementation="js">
				<Drawer
					variant="temporary"
					anchor={"right"}
					open={mobileOpen}
					classes={{
						paper: classes.drawerPaper,
					}}
					onClose={handleDrawerToggle}
				>
					<div className={classes.appResponsive}>
						{leftLinks}
						{rightLinks}
					</div>
				</Drawer>
			</Hidden>
		</AppBar>
	);
}
